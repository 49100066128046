import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererEmptyDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__result-empty text-sm w-full empty:hidden',
    content: 'block mt-4',
    contentLink: 'underline hover:no-underline transition-common hover:no-underline',
    // prázdný výsledek vyhledávání všeobecný
    primary: {
        container: 'text-center',
    },
    // prázdný výsledek vyhledávání produkty
    secondary: {},
    // prázdný výsledek vyhledávání ostatní
    tertiary: {},
};
export function whispererEmptyClassNames(...args) {
    return getMemoizedFinalClassnames('whispererEmptyClassNamesNew', whispererEmptyDefaultClassNames, ...args);
}
