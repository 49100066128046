'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { WhispererResult } from '../WhispererResult';
import { useWhispererStore } from '../WhispererStoreClient';
import { listingSearchHistoryClassNames } from './ListingSearchHistory.styles';
import { WhispererHeading } from './WhispererHeading';
import { WhispererItem } from './WhispererItem';
export const ListingSearchHistory = () => {
    const finalStyles = listingSearchHistoryClassNames();
    const { t } = useI18n('Whisperer');
    const { data, isSearchHistoryVisible, clearSearchHistory, searchUrl } = useWhispererStore();
    if (!isSearchHistoryVisible) {
        return null;
    }
    return (_jsx("div", { className: finalStyles.container, children: _jsx(WhispererResult, { design: "secondary", styles: { container__custom: finalStyles.content }, children: _jsxs(_Fragment, { children: [_jsx(WhispererHeading, { heading: t('searchHistory') }), data?.searchHistory?.map((single, i) => {
                        return (_jsx("div", { children: _jsx(WhispererItem, { heading: single, href: `${searchUrl}/${encodeURI(single)}` }) }, `searchHistory-${i}-${single}`));
                    }), _jsx("button", { type: "button", onClick: clearSearchHistory, className: finalStyles.resetBtn, children: t('searchHistoryPurge') })] }) }) }));
};
