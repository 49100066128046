import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const CreditsDocument = gql `
    query credits($args: CreditArgs) {
  credits(args: $args) {
    amount
    catalogCode
    created
    currency
    customer
    customerDescription
    id
  }
}
    `;
/**
 * __useCreditsQuery__
 *
 * To run a query within a React component, call `useCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCreditsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CreditsDocument, options);
}
export function useCreditsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CreditsDocument, options);
}
export function useCreditsSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CreditsDocument, options);
}
export const CreditItemsDocument = gql `
    query creditItems($args: CreditArgs) {
  creditItems(args: $args) {
    amount
    availableAmount
    created
    creditBalanceId
    detail
    id
    notUsedCredit
    source
    status
    type
    usedAmount
    validTo
  }
}
    `;
/**
 * __useCreditItemsQuery__
 *
 * To run a query within a React component, call `useCreditItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditItemsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCreditItemsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CreditItemsDocument, options);
}
export function useCreditItemsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CreditItemsDocument, options);
}
export function useCreditItemsSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CreditItemsDocument, options);
}
export const CreditHistoryDocument = gql `
    query creditHistory($args: CreditArgs) {
  creditHistory(args: $args) {
    amount
    created
    increase
    messageArgs
    type
  }
}
    `;
/**
 * __useCreditHistoryQuery__
 *
 * To run a query within a React component, call `useCreditHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditHistoryQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCreditHistoryQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CreditHistoryDocument, options);
}
export function useCreditHistoryLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CreditHistoryDocument, options);
}
export function useCreditHistorySuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CreditHistoryDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_CreditsQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CreditsDocument,
    });
};
export const getData_CreditItemsQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CreditItemsDocument,
    });
};
export const getData_CreditHistoryQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CreditHistoryDocument,
    });
};
