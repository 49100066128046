import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getLocalizationsSetup } from '@edeeone/juan-core/utils/localization';
import Image from 'next/image';
import { LangDropdown } from '@edeeone/juan-core/components/language/LangDropdown';
import { LangModal } from '@edeeone/juan-core/components/language/LangModal';
import { languageClassNames } from '@edeeone/juan-core/components/language/language.styles';
export const flagPath = CONFIG.lang.flagSvgPath || '/flag/';
export const Language = ({ styles = CONFIG?.componentSettings?.juanCore?.Language?.styles, design = CONFIG?.componentSettings?.juanCore?.Language?.design ?? 'primary', showCountryFlags = CONFIG?.componentSettings?.juanCore?.Language
    ?.showCountryFlags ?? true, showCountryStrings = CONFIG?.componentSettings?.juanCore?.Language
    ?.showCountryStrings ?? false, arrowIcon = CONFIG?.componentSettings?.juanCore?.Language?.arrowIcon ??
    'arrow', flagWidth = CONFIG?.componentSettings?.juanCore?.Language?.flagWidth ?? 15, flagHeight = CONFIG?.componentSettings?.juanCore?.Language?.flagHeight ?? 15, }) => {
    const finalStyles = languageClassNames(styles, design);
    const { showCountry, showCurrency, showLang, oneCurrencyPerLanguage } = getLocalizationsSetup();
    const location = useLocation();
    const { country, currency, region, locale, pageTreeId } = location;
    const showModal = design === 'tertiary' ||
        [showCountry, showCurrency, showLang]?.reduce((acc, current) => {
            return acc + (current ? 1 : 0);
        }, 0) > 1;
    let langIcon = 'globe';
    if (showCountry && showCountryFlags) {
        langIcon = 'flag';
    }
    else if ((showCountry && !showCountryFlags) || (showLang && !showCountry)) {
        langIcon = 'globe';
    }
    else if (showCurrency && !showCountry && !showLang) {
        langIcon = 'currency';
    }
    const buttonContent = (_jsxs("span", { className: finalStyles.container, children: [_jsxs("span", { className: twMergeJuan(finalStyles.item, (langIcon != 'flag' || !showModal) && finalStyles.item__dropdown), children: [(langIcon === 'globe' || langIcon === 'currency') && (_jsx(SvgSpriteIcon, { icon: langIcon, className: finalStyles.icon, ariaHidden: true })), langIcon === 'flag' && (_jsx(Image, { src: `${flagPath}${country?.toLowerCase()}.svg`, alt: '', width: flagWidth, height: flagHeight, unoptimized: true, className: finalStyles.icon })), showCountryStrings && _jsx("span", { children: country?.toUpperCase() })] }), showLang && (_jsx("span", { className: finalStyles.item, children: process.env.JUAN_LANGUAGE?.[locale] || locale })), showCurrency && _jsx("span", { className: finalStyles.item, children: currency }), !showModal && (_jsx(SvgSpriteIcon, { icon: arrowIcon, className: finalStyles.iconDropdown, ariaHidden: true }))] }));
    if (!showCountry && !showCurrency && !showLang)
        return null;
    if (design === 'tertiary') {
        return (_jsx(LangModal, { buttonAlt: buttonContent, showCountryFlags: showCountryFlags }));
    }
    return (_jsxs(_Fragment, { children: [!showModal && (_jsx(LangDropdown, { showCountryFlags: showCountryFlags, pageTreeId: pageTreeId, children: buttonContent })), showModal && (_jsx(LangModal, { button: buttonContent, showCountryFlags: showCountryFlags }))] }));
};
