import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererInputDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__input-container max-w-[761px] inline-flex content-center items-center gap-5 rounded-full overflow-hidden bg-gray-10 h-[54px] pr-1 sm:absolute sm:top-0 w-full sm:left-0',
    container__active: 'z-50 sm:group-[:not(.is-active)]:z-auto sm:group-[.is-active]:z-50',
    icon: 'hidden ml-[24px] w-8 h-8 fill-black mr-5 lg:block lg:fill-gray-30 shrink-0',
    button: 'hidden',
    input: 'fulltext-autocomplete__input ml-10 text-sm leading-8 bg-transparent focus:outline-none h-full w-full p-0 border-none lg:ml-0 placeholder:text-gray-60',
    loader: 'w-6 h-6 animate-spin mr-6',
    submitMobile: 'fulltext-autocomplete__submit fulltext-autocomplete__submit--mobile block lg:hidden mr-10',
    submitMobileIcon: 'ico block mx-0',
    submit: 'fulltext-autocomplete__submit fulltext-autocomplete__submit--desktop hidden lg:inline-flex',
    submit_btn: '',
    submit_ico: '!hidden',
    submit_content: '',
};
export function whispererInputClassNames(...args) {
    return getMemoizedFinalClassnames('whispererInputClassNamesNew', whispererInputDefaultClassNames, ...args);
}
