'use client';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client/react/hooks';
export const useStorageImage = function (fileId) {
    const { data } = useQuery(gql(process.env.STORAGE_IMAGE_QUERY), {
        variables: {
            fileId,
        },
        skip: fileId == null || fileId === 0,
    });
    return data?.storageFile;
};
