'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dropdownItemProductClassNames } from '@edeeone/juan-core/components/dropdown/DropdownItemProduct.styles';
import { Image } from '@edeeone/juan-core/components/image/Image';
import { StorageImage } from '@edeeone/juan-core/components/image/StorageImage';
import { Price } from '@edeeone/juan-core/components/price/Price';
import Link from 'next/link';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { removeTags } from '@edeeone/string/removeTags';
export const DropdownItemProduct = ({ name, url, prices, motive, quantity, description, children, styles = CONFIG?.componentSettings?.juanCore?.DropdownItemProduct?.styles, imageWidth = CONFIG?.componentSettings?.juanCore?.DropdownItemProduct
    ?.imageWidth ?? 75, imageHeight = CONFIG?.componentSettings?.juanCore?.DropdownItemProduct
    ?.imageHeight ?? 75, aspectRatio = CONFIG?.componentSettings?.juanCore?.DropdownItemProduct
    ?.aspectRatio ?? 'square', imageDesign = CONFIG?.componentSettings?.juanCore?.DropdownItemProduct
    ?.imageDesign ?? 'secondary', }) => {
    const finalStyles = dropdownItemProductClassNames(styles);
    const { t } = useI18n('DropdownItemProduct');
    const isMotiveNumber = typeof motive === 'number';
    return (_jsxs("li", { className: finalStyles.container, children: [_jsx(Link, { href: url, legacyBehavior: true, children: _jsxs("div", { className: finalStyles.content, children: [_jsxs("div", { className: finalStyles.motiveContainer, children: [isMotiveNumber && (_jsx(StorageImage, { motiveId: motive, width: imageWidth, height: imageHeight, alt: removeTags(name), aspectRatio: aspectRatio, design: imageDesign, style: { objectFit: 'contain' }, styles: { content__custom: finalStyles.motive } })), !isMotiveNumber && (_jsx(Image, { src: motive?.files?.[0]?.src, alt: motive?.files?.[0]?.alt || removeTags(name) || '', width: imageWidth, height: imageHeight, aspectRatio: aspectRatio, design: imageDesign, style: { objectFit: 'contain' }, styles: { content__custom: finalStyles.motive } }))] }), _jsxs("div", { className: finalStyles.text, children: [_jsx("h3", { className: finalStyles.title, children: name }), description && _jsx("p", { className: finalStyles.perex, children: description }), _jsxs("div", { className: finalStyles.quantityPrice, children: [_jsx(Price, { zeroPriceText: t('free'), design: 'quaternary', prices: prices }), quantity && _jsx("p", { className: finalStyles.quantity, children: quantity })] })] })] }) }), children] }));
};
