import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const megamenuItemDefaultClassNames = /* tw */ {
    li: 'megamenu__item w-full xl:w-auto empty:hidden',
    li__level0: '',
    li__level1: 'xl:basis-[calc(100%/4_-_1rem_-_1rem/4)]',
    li__haveChildren: 'have-children',
    li_content: 'group pointer-events-auto',
    overlay: '',
    overlay__open: 'overlay-common z-10',
    button: 'text-gray-70 flex gap-4 place-content-center justify-center',
    header: 'megamenu__header flex p-6 mb-2 border-b border-gray-20 min-h-[9.1rem] xl:hidden justify-between items-center',
    panel: 'megamenu__subpanel flex transition-all fixed w-screen h-screen overflow-y-auto custom-scrollbar xl:overflow-y-visible top-0 bg-white left-0 flex-col sm:max-w-[40rem] xl:items-center md:left-[40rem] xl:absolute xl:left-0 xl:right-0 xl:w-auto xl:max-w-none xl:h-auto xl:top-full xl:p-20 xl:rounded-3xl xl:border-gray-20 xl:border xl:shadow-common z-10 xl:-mx-6',
    panel__level0: '',
    panel__level1: 'md:left-0 xl:hidden',
    panel__closed: 'hidden',
    backButton: 'megamenu__back-btn inline-flex items-center gap-4 text-black text-sm font-medium leading-[3rem] hover:underline transition-common',
    backButton_icon: 'megamenu__backBtn-ico w-6 h-12 rotate-90 fill-black',
    list: 'megamenu__nav w-full pl-8 pr-12 flex flex-col items-start xl:p-0 xl:flex-row xl:flex-wrap xl:gap-4 xl:columns-4',
    showAll: 'megamenu__show-all-link order-first text-gray-60 underline hover:no-underline transition-common text-xs py-6 w-full block mb-2 xl:hidden',
    showAllButton: 'megamenu__show-all-btn xl:order-last hidden xl:block pt-12 basis-full',
    closeButton: 'megamenu__close-btn radius-common p-6 outline-none w-[4.6rem] h-[4.6rem] bg-white bg-opacity-80 group/close md:hidden',
    closeButtonIcon: 'megamenu__close-ico ico w-6 h-6 fill-gray-60 group-hover/close:fill-black',
    containerAnimation: 'ease duration-75 delay-100',
    containerFrom: 'opacity-0 -translate-x-full xl:translate-x-0',
    containerTo: 'opacity-100 translate-x-0',
};
export function megamenuItemClassNames(...args) {
    return getMemoizedFinalClassnames('megamenuItemClassNames', megamenuItemDefaultClassNames, ...args);
}
