import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const shippingDiscountDefaultClassNames = /* tw */ {
    container: 'shipping-discount flex items-center justify-center gap-6',
    container__custom: '',
    icon: 'shipping-discount ico w-8 h-8 fill-black',
    text: 'shipping-discount__text text-xs text-gray-60',
    text_b: 'text-black font-semibold',
};
export function shippingDiscountClassNames(...args) {
    return getMemoizedFinalClassnames('shippingDiscountClassNames', shippingDiscountDefaultClassNames, ...args);
}
