'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { DropdownItemProduct } from '@edeeone/juan-core/components/dropdown/DropdownItemProduct';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useWishlist } from '@edeeone/shop-wishlist/hooks/useWishlist';
import { headerWishlistItemClassNames } from './HeaderWishlistItem.styles';
export const HeaderWishlistItem = ({ productId, styles = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlistItem?.styles, buttonDesign = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlistItem
    ?.buttonDesign ?? 'tertiary', buttonIcon = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlistItem
    ?.buttonIcon ?? 'cross', buttonIconSize = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlistItem
    ?.buttonIconSize ?? '10', ...props }) => {
    const finalStyles = headerWishlistItemClassNames(styles);
    const { t } = useI18n('HeaderWishlist');
    const { removeProductFromWishlist } = useWishlist();
    const handleRemoveItemFromWishlist = async () => {
        await removeProductFromWishlist(productId);
    };
    return (_jsx(DropdownItemProduct, { ...props, children: _jsx("form", { action: handleRemoveItemFromWishlist, children: _jsx(Button, { type: "submit", design: buttonDesign, icon: buttonIcon, iconSize: buttonIconSize, styles: { button__custom: finalStyles.button }, title: t('remove') }) }) }));
};
