import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const megamenuDefaultClassNames = /* tw */ {
    container: 'megamenu__container w-full group xl:relative xl:min-h-8',
    container__active: 'is-open',
    button: 'megamenu__toggle-btn xl:hidden',
    overlay: 'megamenu__overlay transition-common hidden group-[.is-open]:block overlay-common !pointer-events-auto z-20 xl:pointer-events-none',
    overlay__childOpened: 'xl:block',
    overlay__hidden: 'xl:!hidden',
    header: 'megamenu__header flex p-6 mb-4 border-b border-gray-20 justify-between flex-row-reverse items-center min-h-[9.1rem] xl:hidden',
    closeButton: 'megamenu__close-btn radius-common p-6 outline-none w-[4.6rem] h-[4.6rem] bg-white bg-opacity-80 group/close',
    closeButtonIcon: 'megamenu__close-ico ico w-6 h-6 fill-gray-60 group-hover/close:fill-black',
    panel: 'megamenu__panel fixed transition-all top-0 left-0 z-50 w-screen h-dvh sm:max-w-[40rem] bg-white xl:bg-gray-70 xl:block xl:static xl:w-full xl:h-auto xl:max-h-[5.3rem] xl:max-w-none group',
    panelContent: 'megamenu__panel-content flex flex-col overflow-y-auto xl:overflow-y-visible custom-scrollbar h-dvh xl:h-auto',
    panel__childClosed: 'bg-white',
    panel__childOpened: 'bg-gray-10 is-child-open',
    panel__closed: 'hidden',
    list: 'megamenu__nav w-full pl-8 pr-12 flex flex-col xl:flex-row xl:list-none xl:px-16 xl:hover:z-50 xl:hover:relative',
    mobileHeader: 'w-full',
    containerAnimation: 'ease duration-75',
    containerFrom: 'opacity-0 xl:opacity-100 -translate-x-full xl:translate-x-0',
    containerTo: 'opacity-100 translate-x-0',
};
export function megamenuClassNames(...args) {
    return getMemoizedFinalClassnames('megamenuClassNames', megamenuDefaultClassNames, ...args);
}
