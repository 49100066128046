import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const cookieBarDefaultClassNames = /* tw */ {
    container: 'cookie-bar flex flex-col gap-16 content-center p-12 md:p-16 justify-center z-30 shadow-2xl items-start transition-transform duration-150 delay-500',
    container__hidden: 'cookie-bar--hidden',
    container__visible: 'cookie-bar--visible',
    image: 'cookie-bar__image hidden mt-6 w-48 h-48 lg:block',
    content: 'cookie-bar__content flex flex-col gap-8 w-full',
    heading: 'cookie-bar__heading text-lg leading-normal font-semibold',
    description: 'cookie-bar__description mb-4 text-gray-60 text-xs',
    description_a: 'underline transition-common hover:no-underline',
    link: 'cookie-bar__link text-black text-xs underline',
    buttons: 'cookie-bar__buttons flex flex-col gap-4',
    // cookies lišta na spodní straně obrazovky
    primary: {
        container: 'bottom-0 left-0 w-screen bg-white md:flex-row fixed shadow-black',
        content: 'max-w-[760px]',
        buttons: 'md:flex-row md:gap-6',
    },
    // cookies lišta v modálním okně
    secondary: {
        container: 'flex-col gap-12 bg-white shadow-md p-12 md:px-30 md:pt-16 md:pb-24 max-h-full overflow-y-auto custom-scrollbar',
        image: 'mx-auto',
        content: 'max-w-6xl items-center mx-auto',
        buttons: 'lg:flex-row lg:gap-6',
    },
};
export function cookieBarClassNames(...args) {
    return getMemoizedFinalClassnames('cookieBarClassNames', cookieBarDefaultClassNames, ...args);
}
