'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useLogout } from '@edeeone/edee-registration/components/useLogout';
import { useRouter } from 'next/navigation';
export const LogoutButton = ({ children }) => {
    const router = useRouter();
    const { logout } = useLogout({
        onComplete: () => {
            return router.push('/');
        }, //TODO JTR - root
    });
    return (_jsx("div", { onClick: () => {
            logout();
        }, children: children }));
};
