import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const listingCategoriesDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__result--categories',
    headingContainer: 'w-full',
    moreLink: 'fulltext-autocomplete__more text-gray-60 text-xs underline hover:no-underline transition-common px-4 py-3 inline-block cursor-pointer',
    moreLink__active: 'no-underline',
};
export function listingCategoriesClassNames(...args) {
    return getMemoizedFinalClassnames('listingCategoriesClassNamesNew', listingCategoriesDefaultClassNames, ...args);
}
