import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const dropdownDefaultClassNames = /* tw */ {
    container: 'dropdown w-auto inline-block',
    container__custom: '',
    overlay: 'dropdown__overlay overlay-common z-40 transform',
    button: 'dropdown__opener z-[49] relative',
    button__custom: '',
    popover: 'dropdown__popover transition-popup absolute z-50 w-screen max-w-lg -mt-2',
    popoverArrowFill: 'white',
    popoverArrowStroke: 'gray-20',
    popoverArrowStrokeWidth: 1,
    popoverHeader: 'dropdown__popover-header bg-transparent block h-8 relative z-100',
    popoverContent: 'dropdown__popover-content transform px-6 pt-2 pb-4 bg-white overflow-visible rounded-3xl shadow-horizontal-filter border border-gray-20 pointer-events-auto',
    popoverContent__custom: '',
    header: 'dropdown__header flex flex-col items-center relative justify-center text-sm font-semibold pt-6 pb-8 gap-8 border-b border-gray-20',
    header__close: 'px-14',
    header__hideBorder: 'border-b-0 pt-6 pb-4 text-xs font-normal text-gray-70',
    content: 'dropdown__content flex flex-col gap-10 py-8 empty:hidden',
    closeButton: 'dropdown__closeBtn absolute right-6 top-8 w-6 h-6',
    closeButtonIcon: 'ico w-full h-full',
    footer: 'dropdown__footer flex flex-col relative text-sm px-6 py-8 gap-8 border-t border-gray-20',
};
export function dropdownClassNames(...args) {
    return getMemoizedFinalClassnames('dropdownClassNames', dropdownDefaultClassNames, ...args);
}
