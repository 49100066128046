const regionList = process.env.JUAN_REGIONS;
export const getLocalizationsSetup = () => {
    let showLang = false;
    let showCurrency = false;
    let oneCurrencyPerLanguage = true;
    regionList?.map((c) => {
        if (c?.languages?.length > 1 && !showLang) {
            showLang =
                c?.languages?.some((l, _i, o) => {
                    return l?.lang !== o?.[0]?.lang;
                }) || showLang;
        }
        if (c?.languages?.length > 1 && !showCurrency) {
            showCurrency =
                c?.languages?.some((l, _i, o) => {
                    return l?.currency !== o?.[0]?.currency;
                }) || showCurrency;
        }
    });
    if (showCurrency && showLang) {
        const langs = getLanguages();
        // console.log('showCurrency && showLang', langs);
        regionList?.map((r) => {
            langs?.map((l) => {
                if (r?.languages?.filter((ln) => {
                    // console.log('lng', ln?.lang, l?.lang, ln?.lang === l?.lang);
                    return ln?.lang === l?.lang;
                })?.length > 1) {
                    oneCurrencyPerLanguage = false;
                }
            });
        });
    }
    return {
        showCountry: regionList?.length > 1,
        showLang,
        showCurrency,
        oneCurrencyPerLanguage,
    };
};
export const getCountries = () => {
    return regionList?.map((r) => {
        return r?.countryCode;
    });
};
export const getLanguages = (country) => {
    let langs = [];
    regionList?.forEach((c) => {
        if (country === c?.countryCode || country === undefined) {
            c?.languages?.forEach((l) => {
                if (!langs?.find((cl) => {
                    return cl?.lang === l?.lang;
                })) {
                    langs.push(l);
                }
            });
        }
    });
    return langs;
};
export const getCurrencies = (country, language) => {
    let currencyList = [];
    regionList?.forEach((c) => {
        if (country === c?.countryCode || country === undefined) {
            c?.languages?.forEach((l) => {
                if ((l?.lang === language || language === undefined) &&
                    !currencyList?.find((cl) => {
                        return cl?.currency === l?.currency;
                    })) {
                    currencyList.push(l);
                }
            });
        }
    });
    return currencyList;
};
