'use client';
import { useCookiesRefresh } from '@edeeone/juan-core/Cookies';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useRegistrationLogoutMutation } from '../graphql/registration.codegen';
export const useLogout = function ({ onComplete, onError, } = {}) {
    const [loading, setLoading] = useState(false);
    const cookiesRefresh = useCookiesRefresh();
    const { refresh: refreshPage } = useRouter();
    const [registrationLogoutMutation, { data, ...mutationResult }] = useRegistrationLogoutMutation({
        onError,
    });
    const logout = async () => {
        setLoading(true);
        let data = {};
        try {
            ({ data } = await registrationLogoutMutation());
        }
        catch {
            setLoading(false);
        }
        refreshPage();
        await refreshTags(['userProfile']);
        cookiesRefresh();
        setLoading(false);
        if (onComplete) {
            onComplete(data?.registrationLogout);
        }
    };
    return {
        ...mutationResult,
        logout,
        loading,
    };
};
