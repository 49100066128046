import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const tagDefaultClassNames = /* tw */ {
    container: 'tag inline-flex items-center align-middle justify-center text-center text-white rounded-full',
    container__custom: '',
    icon: 'tag__ico ico mr-2',
    content: 'tag__content whitespace-nowrap',
    // tag s primární barvou (štítky na dlaždici produktů, v detailu produktu)
    primary: {
        container: 'bg-primary',
        icon: 'fill-white',
    },
    // tag se sekundární barvou
    secondary: {
        container: 'bg-green-light',
        icon: 'fill-white',
    },
    // transparentní tag
    tertiary: {
        container: '',
        icon: 'fill-white',
    },
    // šedý tag
    quaternary: {
        container: 'bg-gray-70',
        icon: 'fill-gray-30',
    },
    // šedý tag (výpis článků ve fulltextu)
    quinary: {
        container: 'bg-gray-60',
    },
    // zvýrazněný tag (štítek slevy)
    danger: {
        container: 'bg-red/80',
        icon: 'fill-white',
    },
    // velikost XS
    xs: {
        container: 'text-xxs px-4',
        icon: 'mr-4 w-5 h-5',
    },
    // velikost SM
    sm: {
        container: 'w-14 h-14 text-2xs p-2',
    },
    // velikost MD
    md: {
        container: 'text-xxs leading-[1.4rem] py-2 px-4',
    },
    // velikost LG
    lg: {
        container: 'text-tag leading-[1.5rem] py-2 px-6',
    },
    // velikost XL
    xl: {
        container: 'w-16 h-16 font-semibold text-tag  py-2 px-6',
    },
};
export function tagClassNames(...args) {
    return getMemoizedFinalClassnames('tagClassNames', tagDefaultClassNames, ...args);
}
