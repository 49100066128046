import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const langDropdownDefaultClassNames = /* tw */ {
    container: 'dropdown-lang',
    item: 'dropdown-lang__item flex gap-4 items-center mb-10',
    item__last: 'mb-0',
    radio: 'dropdown-lang__item-radio relative h-[2.2rem] w-[2.2rem] flex shrink-0 items-center',
    radioInput: 'dropdown-lang__item-radio-input absolute outline-none appearance-none h-[2.2rem] w-[2.2rem] border-radio radius-radio bg-white cursor-pointer peer transition-common group-hover/item:border-gray-60 group-active/item:bg-black',
    radioInput__checked: 'bg-black',
    radioIcon: 'dropdown-lang__item-radio-ico absolute hidden pointer-events-none fill-white w-4 h-4 mx-[.6rem] group-active/item:block',
    radioIcon__checked: 'block',
    icon: 'dropdown-lang__item-ico ico w-6 h-6',
    label: 'dropdown-lang__item-label text-sm font-normal text-gray-70 group-hover/item:text-black transition-commmon',
    label__checked: 'text-black font-medium',
    iconDropdown: 'w-6 h-6 mr-6',
    buttonSearch: '',
};
export function langDropdownClassNames(...args) {
    return getMemoizedFinalClassnames('langDropdownClassNames', langDropdownDefaultClassNames, ...args);
}
