'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { twMerge } from 'tailwind-merge';
import { WhispererResult } from '../WhispererResult';
import { whispererResultsClassNames } from '../WhispererResults.styles';
import { useWhispererStore } from '../WhispererStoreClient';
import { WhispererHeading } from './WhispererHeading';
import { WhispererProduct } from './WhispererProduct';
export const ListingRecentlyVisited = () => {
    const finalStyles = whispererResultsClassNames();
    const { t } = useI18n('Whisperer');
    const { data, isRecentlyVisitedVisible, recentlyVisitedCount } = useWhispererStore();
    const dataVisited = data?.recentlyVisited?.trackingRecentlyVisited?.recordStrip?.data;
    if (isRecentlyVisitedVisible) {
        return (_jsx(_Fragment, { children: (dataVisited?.length || 0) > 0 && (_jsx("div", { className: twMerge(finalStyles.listingProducts, finalStyles.listingProducts__recently), children: _jsx(WhispererResult, { design: "primary", children: _jsxs(_Fragment, { children: [_jsx(WhispererHeading, { heading: t('recentlyVisited'), count: recentlyVisitedCount }), dataVisited?.map((item, i) => {
                                const single = {
                                    ...item,
                                    ...item.attributes,
                                };
                                return (_jsx("div", { children: _jsx(WhispererProduct, { item: item }) }, `recentlyVisited-${single?.primaryKey}`));
                            })] }) }) })) }));
    }
    return null;
};
export const RecentlyVisitedAll = () => {
    const finalStyles = whispererResultsClassNames();
    const { data, recentlyVisitedCount, isRecentlyVisitedVisible, trackingUrl } = useWhispererStore();
    const { t } = useI18n('Whisperer');
    const dataVisited = data?.recentlyVisited?.trackingRecentlyVisited?.recordStrip?.data || [];
    if (isRecentlyVisitedVisible &&
        trackingUrl &&
        dataVisited?.length < recentlyVisitedCount) {
        return (_jsx("div", { className: finalStyles.buttonContainer, children: _jsx(Button, { href: trackingUrl, children: t('recentlyVisitedAll') }) }));
    }
    return null;
};
