'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useBreakpoints } from '@edeeone/juan-core/hooks/useBreakpoints';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
// TODO nahradit ariakitem
import { Popover, Transition } from '@headlessui/react';
import { useSearchParams } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';
import { Modal } from '../modal/Modal';
import { SvgSpriteIcon } from '../svgSpriteIcon/SvgSpriteIcon';
import { dropdownButtonWrapperClassNames } from './DropdownButtonWrapper.styles';
const CallOnRouteChange = function ({ onChange }) {
    const searchParams = useSearchParams();
    const orderBy = searchParams.get('orderBy');
    useEffect(() => {
        onChange();
    }, [orderBy]);
    return null;
};
export const DropdownButtonWrapper = function ({ styles, disabled, hasSelected, icon = 'sort', showIconLeft, children, opener, asDropdown = false, dropdownTitle, }) {
    const [open, setIsOpen] = useState(false);
    const searchParams = useSearchParams();
    const closeModal = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        closeModal();
    }, [searchParams]);
    const { breakpoints } = useBreakpoints();
    const isMobile = !breakpoints?.includes('md');
    const finalStyles = dropdownButtonWrapperClassNames(styles);
    return asDropdown && !isMobile ? (_jsx(Popover, { children: ({ open, close }) => {
            return (_jsxs(_Fragment, { children: [_jsxs(Popover.Button, { className: twMergeJuan(finalStyles.opener, disabled && finalStyles.opener__disabled, open && finalStyles.opener__open, open && hasSelected && finalStyles.opener__openSelected), onClick: () => {
                            return setIsOpen(!open);
                        }, children: [!showIconLeft && opener, _jsx(SvgSpriteIcon, { icon: icon, ariaHidden: true, className: twMergeJuan(finalStyles.openerIcon, disabled && finalStyles.openerIcon__disabled, open && finalStyles.openerIcon__open) }), showIconLeft && opener] }), _jsx(Transition.Child, { as: Fragment, enter: finalStyles.contentAnimation, enterFrom: finalStyles.contentFrom, enterTo: finalStyles.contentTo, leave: finalStyles.contentAnimation, leaveFrom: finalStyles.contentTo, leaveTo: finalStyles.contentFrom, children: _jsx(Popover.Panel, { unmount: false, className: finalStyles.contentDropdown, children: children }) }), _jsx(CallOnRouteChange, { onChange: close })] }));
        } })) : (_jsxs(_Fragment, { children: [_jsxs("button", { className: twMergeJuan(finalStyles.opener, disabled && finalStyles.opener__disabled, open && finalStyles.opener__open, open && hasSelected && finalStyles.opener__openSelected), onClick: () => {
                    return setIsOpen(!open);
                }, children: [!showIconLeft && opener, _jsx(SvgSpriteIcon, { icon: icon, ariaHidden: true, className: twMergeJuan(finalStyles.openerIcon, disabled && finalStyles.openerIcon__disabled, open && finalStyles.openerIcon__open) }), showIconLeft && opener] }), _jsx(Modal, { design: "secondary", isOpen: open, setIsOpen: closeModal, title: dropdownTitle, children: _jsx("div", { className: finalStyles.container, children: _jsx("div", { className: finalStyles.contentModal, children: children }) }) })] }));
};
