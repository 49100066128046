import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const langFormDefaultClassNames = /* tw */ {
    root: 'lang-switcher-form-page',
    form: 'lang-switcher-form text-left xsm:w-full xsm:max-content-narrow flex flex-col mx-6 xsm:mx-auto',
    title: 'lang-switcher-form__title mt-8 mb-12',
    formContent: 'lang-switcher-form__content',
    btnContainer: 'lang-switcher-form__buttons flex gap-12 mt-20 mb-12',
    btnPrimary: 'grow-0',
    formGroupLabel__radio: 'uppercase mb-8 font-semibold tracking-wide',
    formGroup__radio: 'mb-12',
    formGroup__radioLabel: 'lang-switcher-form__label flex gap-4 items-center',
    icon: 'lang-switcher-form__ico w-[2.2rem] h-[2.2rem] shrink-0',
    secondary: {
        root: 'w-full',
    },
};
export function langFormClassNames(...args) {
    return getMemoizedFinalClassnames('langFormClassNames', langFormDefaultClassNames, ...args);
}
