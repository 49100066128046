'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from '@edeeone/juan-core/components/modal/Modal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { ResponsiveDisplay } from '@edeeone/juan-core/components/ResponsiveDisplay';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { whispererClassNames } from './Whisperer.styles';
import { WhispererContainer } from './WhispererContainer';
import { useWhispererStore } from './WhispererStoreClient';
const WhispererModalButton = () => {
    const finalStyles = whispererClassNames();
    const { t } = useI18n('Whisperer');
    const { opened, setOpened, inPortal } = useWhispererStore();
    const isOpenedInPortal = opened && inPortal;
    return (_jsxs("div", { className: finalStyles.modalToggleContainer, children: [_jsx("button", { type: 'button', onClick: (e) => {
                    setOpened(true, true);
                }, "aria-label": t('label'), children: _jsx(SvgSpriteIcon, { icon: "headerMagnifier", className: finalStyles.modalToggle }) }), isOpenedInPortal && (_jsx(Modal, { design: "tertiary", setIsOpen: () => {
                    setOpened(false);
                }, isOpen: isOpenedInPortal, styles: { container__custom: finalStyles.modalContainer }, children: isOpenedInPortal && _jsx(WhispererContent, {}) }))] }));
};
const WhispererContent = () => {
    const finalStyles = whispererClassNames();
    const { handleSubmit } = useWhispererStore();
    return (_jsx("div", { className: finalStyles.container, children: _jsx("form", { method: 'get', onSubmit: handleSubmit, children: _jsx(WhispererContainer, {}) }) }));
};
export const WhispererNewWithoutStore = ({ smInPortal = false, }) => {
    const InPortal = _jsx(WhispererModalButton, {});
    if (smInPortal) {
        return InPortal;
    }
    return (_jsxs(_Fragment, { children: [_jsx(ResponsiveDisplay, { visible: true, children: _jsx(WhispererContent, {}) }), _jsx(ResponsiveDisplay, { visible: false, children: InPortal })] }));
};
