'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { btnTopClassNames } from '@edeeone/juan-core/components/footer/BtnTop.styles';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
export const BtnTop = ({ buttonDesign = CONFIG?.componentSettings?.juanCore?.BtnTop?.buttonDesign ??
    'senary', buttonIcon = CONFIG?.componentSettings?.juanCore?.BtnTop?.buttonIcon ??
    'arrowTop', }) => {
    const finalStyles = btnTopClassNames();
    const { t } = useI18n('BtnTop');
    return (_jsx(Button, { href: "#layoutHeader", icon: buttonIcon, design: buttonDesign, ariaLabel: t('BtnTop.label'), prefetch: false, onClick: (e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, styles: {
            button__custom: finalStyles.btn,
            icon__custom: finalStyles.ico,
        } }));
};
