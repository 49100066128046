import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const megamenuLinkDefaultClassNames = /* tw */ {
    container: 'megamenu__item-link-container flex flex-col w-full justify-start group/link xl:w-auto',
    container__withChildren: 'have-children',
    item: 'megamenu__item-link group/hlink text-gray-70 transition-common group-[.active]/link:text-black xl:block items-center gap-4',
    item_link: 'megamenu__item-link--link xl:z-10 flex',
    item_link__level0: 'xl:text-white xl:border-b-[3px] border-transparent xl:p-0 group-[.active]/link:font-semibold xl:group-[.active]/link:font-normal xl:group-[.active]/link:text-white group-[.active]/link:border-secondary xl:!flex xl:items-center xl:h-[5.4rem] xl:mx-8',
    item_link__active: 'border-secondary font-semibold lg:font-normal xl:text-shadow-common',
    item_link__level1: 'xl:text-black xl:font-medium xl:py-0 xl:flex xl:gap-6 items-center xl:group-[.active]:text-black ',
    item_link__hasChild: 'hidden xl:block',
    item_motive: 'megamenu__item-link-motive block w-16 h-16 xl:w-[7.5rem] xl:h-[7.5rem]',
    item_motive_image: 'w-full aspect-square',
    item_iconMotive: 'block w-8 h-8 relative',
    item_iconMotiveSvg: 'block w-auto h-auto max-w-full max-h-full',
    item_motive__level0: 'hidden',
    item_button: 'megamenu__item-link--button flex justify-between flex-grow xl:hidden',
    item_content: 'megamenu__item-link-content flex gap-4 transition-common',
    item_content__level0: '',
    item_icon: 'megamenu__item-link-ico ico h-4 w-4 fill-gray-60 transition-common group-[.active]/link:rotate-180',
    list: 'megamenu__list hidden xl:flex flex-col gap-4 pl-36 mb-12 -mt-4',
    list_item: 'megamenu__list-item text-s text-gray-70 transition-common hover:underline hover:text-black',
    // položky megamenu 1. úrovně
    primary: {
        item: 'text-sm font-medium py-6 xl:text-s xl:font-normal',
        item_content: 'group-hover/link:underline group-hover/link:text-black xl:group-hover/link:no-underline xl:group-hover/link:text-white',
    },
    // položky v RWD menu (Můj účt, Porovnání, Oblíbené), přepínač jazyků a footer menu
    secondary: {
        item: 'text-s font-normal py-6 xl:text-sm xl:font-semibold xl:text-black',
        item_content: 'group-hover/link:underline group-hover/link:text-black xl:group-hover/link:no-underline hover:underline',
    },
    // položky megamenu 2. a dalších úrovní
    tertiary: {
        item: 'text-s font-normal py-3',
        item_content: 'group-hover/hlink:text-black group-hover/hlink:underline',
    },
};
export function megamenuLinkClassNames(...args) {
    return getMemoizedFinalClassnames('megamenuLinkClassNames', megamenuLinkDefaultClassNames, ...args);
}
