import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererResultDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__result flex flex-wrap w-full empty:hidden',
    container__custom: '',
    // výpis nalezených produktů a naposledy navštívených produktů
    primary: {
        container: 'fulltext-autocomplete__result--products flex-col gap-6',
    },
    // výpis nalezených kategorií, značek a článků
    secondary: {
        container: 'fulltext-autocomplete__result--links gap-y-4 gap-x-2 sm:flex-col sm:gap-6',
    },
};
export function whispererResultClassNames(...args) {
    return getMemoizedFinalClassnames('whispererResultClassNamesNew', whispererResultDefaultClassNames, ...args);
}
