import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const FieldsPriceFragmentDoc = gql `
    fragment fieldsPrice on PriceForSale {
  priceWithTax
  priceWithoutTax
  currency
  validity
}
    `;
export const ProductPricesFieldsFragmentDoc = gql `
    fragment productPricesFields on Product {
  multiplePricesForSaleAvailable(currency: $currency)
  priceForSale(validNow: true, currency: $currency) {
    ...fieldsPrice
  }
  allPricesForSale(priceLists: $priceLists, currency: $currency) {
    accompanyingPrice(priceLists: $priceLists) {
      priceWithTax
      priceWithoutTax
      currency
      validity
    }
    ...fieldsPrice
  }
  attributes(locale: $locale) {
    saleEnded
  }
}
    ${FieldsPriceFragmentDoc}`;
export const AvailabilityFieldsFragmentDoc = gql `
    fragment availabilityFields on Product {
  stocks {
    attributes {
      quantityOnStock
    }
    referencedEntity {
      attributes(locale: $locale) {
        name
        code
      }
    }
  }
  attributes(locale: $locale) {
    availability
  }
}
    `;
export const ImageFieldsFragmentDoc = gql `
    fragment imageFields on RelatedFiles {
  fileIds
  files {
    src
    alt
  }
}
    `;
export const RecordPageFieldsFragmentDoc = gql `
    fragment recordPageFields on ProductRecordPage {
  first
  empty
  pageNumber
  totalRecordCount
  pageSize
  data {
    primaryKey
    associatedData {
      localization
    }
    categories {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
        }
      }
    }
    relatedProducts {
      attributes {
        category
      }
    }
    variants {
      referencedEntity {
        attributes {
          codeShort
        }
      }
    }
    ...productPricesFields
    ...availabilityFields
    attributes(locale: $locale) {
      name
      descriptionShort
      codeShort
      url
      productType
      ratingVotes
      rating
      motive: relatedFiles(category: "hlavni-motiv") {
        ...imageFields
      }
      minOrderQuantity
      saleEnded
    }
    master {
      referencedPrimaryKey
    }
    tags {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          isVisibleInDetail
          isVisibleInFilter
          isVisibleInListing
        }
        type
      }
    }
    parameterValues(
      filterBy: {inScope: {scope: LIVE, filtering: {attributeVariantEquals: true}}}
    ) {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          order
        }
        parameter {
          attributes {
            orderInParameter
          }
          referencedEntity {
            attributes {
              name
              code
              isVisibleInDetail
            }
          }
        }
      }
    }
  }
}
    ${ProductPricesFieldsFragmentDoc}
${AvailabilityFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const WhispererAllDocument = gql `
    query whispererAll($orderableOnly: Boolean, $query: String!, $region: String!, $pageNumber: Int = 1, $pageSize: Int = 10, $userFilter: [FilterContainer231fb6d3716a4ce6!], $sections: [String!]!, $locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"], $priceType: QueryPriceMode = WITH_TAX) {
  edeeFulltextProduct(
    query: $query
    region: $region
    userFilter: $userFilter
    locale: $locale
    currency: $currency
    priceType: $priceType
    pageNumber: $pageNumber
    pageSize: $pageSize
    orderableOnly: $orderableOnly
  ) {
    recordPage(number: $pageNumber, size: $pageSize) {
      ...recordPageFields
    }
  }
  edeeFulltextCategories(query: $query, limit: 5) {
    totalRecordCount
    data {
      facetEntity {
        primaryKey
        attributes {
          name
          url
          relatedFiles {
            fileIds
            files {
              url
              name
              description
            }
          }
        }
        parents {
          primaryKey
          attributes {
            name
            url
            relatedFiles {
              fileIds
              files {
                url
                name
                description
              }
            }
          }
        }
      }
    }
  }
  edeeFulltextBrands(query: $query, limit: 5) {
    totalRecordCount
    data {
      facetEntity {
        primaryKey
        attributes {
          name
          url
        }
      }
    }
  }
  edeeFulltextGroups(query: $query, limit: 5) {
    totalRecordCount
    data {
      facetEntity {
        primaryKey
        attributes {
          name
          url
          relatedFiles {
            fileIds
          }
        }
      }
    }
  }
  edeeFulltextStageMultiSection(
    query: $query
    sections: $sections
    pageSize: $pageSize
  ) {
    section
    data {
      totalNumberOfRecords
      pageSize
      data {
        title
        uri
        perex
        parameters {
          parameter1
        }
        data {
          parentPageName
          stageObjectId
        }
      }
    }
  }
}
    ${RecordPageFieldsFragmentDoc}`;
/**
 * __useWhispererAllQuery__
 *
 * To run a query within a React component, call `useWhispererAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhispererAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhispererAllQuery({
 *   variables: {
 *      orderableOnly: // value for 'orderableOnly'
 *      query: // value for 'query'
 *      region: // value for 'region'
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      userFilter: // value for 'userFilter'
 *      sections: // value for 'sections'
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *      priceType: // value for 'priceType'
 *   },
 * });
 */
export function useWhispererAllQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(WhispererAllDocument, options);
}
export function useWhispererAllLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(WhispererAllDocument, options);
}
export function useWhispererAllSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(WhispererAllDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_WhispererAllQuery = async function (options) {
    return getGqlData({
        ...options,
        query: WhispererAllDocument,
    });
};
