import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Highlight } from '@edeeone/juan-core/components/highlight/Highlight';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import Link from 'next/link';
import { useWhispererStore } from '../WhispererStoreClient';
import { whispererItemClassNames } from './WhispererItem.styles';
export const WhispererItem = ({ styles, design = 'primary', heading, category, href, }) => {
    const finalStyles = whispererItemClassNames(styles, design);
    const { t } = useI18n('Whisperer');
    const { searchQuery: search } = useWhispererStore();
    const content = (_jsxs(_Fragment, { children: [_jsx(Highlight, { text: heading, search: search, searchAs: _jsx("span", { className: finalStyles.highlight }), as: _jsx("span", { className: finalStyles.label }) }), category && (_jsxs("span", { className: finalStyles.subLabel, children: [t('in'), "\u00A0", category] }))] }));
    return (_jsx(Link, { href: href || '/', className: finalStyles.container, children: content }));
};
