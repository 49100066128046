import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererItemDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__item group/item inline-block transition-common list-none cursor-pointer text-gray-70',
    label: 'text-xs font-normal text-black',
    subLabel: 'hidden lg:inline text-gray-30 ml-2',
    highlight: 'font-medium',
    // tag in small viewports, link in bigger viewports
    primary: {
        container: 'rounded-full sm:rounded-none px-8 py-3 sm:p-0 hover:text-white sm:hover:text-gray-70 bg-gray-10 hover:bg-black sm:bg-transparent sm:hover:bg-transparent sm:hover:underline',
        label: 'group-hover/item:text-white sm:group-hover/item:text-black',
        subLabel: 'group-hover/item:text-white sm:group-hover/item:text-gray-30',
    },
    // link in all viewports
    secondary: {
        container: 'hover:underline',
    },
};
export function whispererItemClassNames(...args) {
    return getMemoizedFinalClassnames('whispererItemClassNamesNew', whispererItemDefaultClassNames, ...args);
}
