'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useConsent } from '@edeeone/juan-core/hooks/useConsent';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import Link from '@edeeone/juan-core/Link';
import { getHref } from '@edeeone/string/getHref';
import Image from 'next/image';
import { useState } from 'react';
import { Button } from '../button/Button';
import { Modal } from '../modal/Modal';
import { cookieBarClassNames } from './cookieBar.styles';
import { CookiePreferencesForm } from './CookiePreferencesForm';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useAfterPaintEffect } from '@edeeone/juan-core/utils/cwv';
export const CookieBar = ({ design = 'primary', styles, imgSrc, imgWidth = 120, imgHeight = 120, options, urlToPrivacyPolicy = getHref('/privacy-policy'), }) => {
    const finalStyles = cookieBarClassNames(styles, design);
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useI18n('CookieBar');
    const { consent, updateConsent } = useConsent();
    useAfterPaintEffect(() => {
        setIsVisible(true);
    }, [], { priority: 'background' });
    // cookies from useCookies are evaluated on the client. The ['INIT'] field is set on the server so that the cookie bar does not flash and is closed until the cookies are read
    if (consent?.length || consent[0] === 'INIT') {
        return null;
    }
    const content = (_jsxs("div", { className: twMergeJuan(finalStyles?.container, isVisible
            ? finalStyles?.container__visible
            : finalStyles?.container__hidden), children: [imgSrc && (_jsx(Image, { className: finalStyles?.image, src: imgSrc, width: imgWidth, height: imgHeight, unoptimized: true, alt: "" })), _jsx(Modal, { isOpen: preferencesOpen, closeOnClickOutside: true, size: "xl", design: "quaternary", title: t('preferencesHeading'), placement: "center", setIsOpen: () => {
                    return setPreferencesOpen(false);
                }, children: _jsx(CookiePreferencesForm, { options: options, urlToPrivacyPolicy: urlToPrivacyPolicy, onSubmit: () => {
                        return setPreferencesOpen(false);
                    } }) }), _jsxs("div", { className: finalStyles?.content, children: [_jsx("h2", { className: finalStyles?.heading, children: t('heading') }), _jsx("p", { className: finalStyles?.description, children: t('detail', {
                            a: (chunks) => {
                                return (_jsx(Link, { href: urlToPrivacyPolicy, legacyBehavior: true, children: _jsx("a", { className: finalStyles.description_a, children: chunks }) }, "detailLink"));
                            },
                        }) }), _jsxs("div", { className: finalStyles?.buttons, children: [_jsx(Button, { design: "secondary", onClick: () => {
                                    return setPreferencesOpen(!preferencesOpen);
                                }, children: t('preferences') }), _jsx(Button, { design: "secondary", onClick: () => {
                                    updateConsent(['TECHNICAL'], true, true);
                                }, children: t('essentials') }), _jsx(Button, { design: "primary", onClick: () => {
                                    updateConsent(['TECHNICAL', 'ANALYTICS', 'MARKETING'], true, true);
                                }, children: t('all') })] })] })] }));
    return (_jsxs(_Fragment, { children: [design === 'primary' && content, design === 'secondary' && (_jsx(Modal, { isOpen: true, placement: "center", size: "xl", design: "quaternary", children: content }))] }));
};
