'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { megamenuClassNames } from '@edeeone/juan-core/components/megamenu/Megamenu.styles';
import { useMegamenuExternalControl } from '@edeeone/juan-core/components/megamenu/MegamenuExternalControl';
import { MegamenuItem } from '@edeeone/juan-core/components/megamenu/MegamenuItem';
import { MegamenuContext } from '@edeeone/juan-core/components/megamenu/useMegamenu';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useBreakpoints } from '@edeeone/juan-core/hooks/useBreakpoints';
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { autoUpdate, offset, shift, useClick, useFloating, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
export const Megamenu = ({ styles = CONFIG?.componentSettings?.juanCore?.Megamenu?.styles, logo, data, showOpenButton = CONFIG?.componentSettings?.juanCore?.Megamenu
    ?.showOpenButton ?? true, maxDepth = CONFIG?.componentSettings?.juanCore?.Megamenu?.maxDepth ?? 3, hideMotiveOnTopLevel = CONFIG?.componentSettings?.juanCore?.Megamenu
    ?.hideMotiveOnTopLevel ?? true, breakPoint = CONFIG?.componentSettings?.juanCore?.Megamenu?.breakPoint ??
    'xl', mobileHeader, contentMenu, }) => {
    const { t } = useI18n('Megamenu');
    const finalStyles = megamenuClassNames(styles);
    const [isOpen, setIsOpen] = useState(false);
    const [childHaveChildren, setChildHaveChildren] = useState(false);
    const [megamenuState, setMegamenuState] = useState([]);
    const { setOpen, open } = useMegamenuExternalControl();
    const handleStateUpdate = (nextState) => {
        setIsOpen(nextState);
        try {
            setOpen(nextState);
        }
        catch (error) {
            console.error(error, 'Megamenu.tsx');
        }
    };
    const handleToggle = (nextState) => {
        if (nextState !== isMenuOpen) {
            handleStateUpdate(nextState);
        }
    };
    const handleClose = () => {
        handleStateUpdate(false);
        setMegamenuState([]);
    };
    useChangePathname({ callback: handleClose, enableSameLinkClick: true });
    const isMenuOpen = open ?? isOpen;
    const childOpen = megamenuState?.length > 0;
    const { refs, context } = useFloating({
        open: isMenuOpen,
        onOpenChange: handleToggle,
        placement: 'bottom',
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(10),
            shift({
                padding: 20,
            }),
        ],
    });
    const click = useClick(context);
    const role = useRole(context, { role: 'menu' });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        role,
    ]);
    const { isMounted } = useTransitionStatus(context);
    const { breakpoints } = useBreakpoints();
    const finalOpen = breakpoints.includes(breakPoint) ? true : open;
    return (_jsx(Transition, { appear: true, show: finalOpen, as: Fragment, children: _jsx("div", { id: "megamenuPopoverContainer", className: twMergeJuan(finalStyles.container, open && finalStyles.container__active), children: _jsxs(MegamenuContext.Provider, { value: {
                    maxDepth,
                    open: megamenuState,
                    setOpen: setMegamenuState,
                    close: handleClose,
                    hideMotiveOnTopLevel,
                }, children: [finalOpen && (_jsx("div", { id: "megamenuOverlayTrap", className: twMergeJuan(finalStyles.overlay, childOpen &&
                            childHaveChildren &&
                            finalStyles.overlay__childOpened), onClick: handleClose })), showOpenButton && (_jsx("button", { className: finalStyles.button, ref: refs.setReference, onClick: () => {
                            return handleToggle(true);
                        }, ...getReferenceProps(), children: t('Megamenu.open') })), _jsx(Transition.Child, { as: Fragment, enter: finalStyles.containerAnimation, enterFrom: finalStyles.containerFrom, enterTo: finalStyles.containerTo, leave: finalStyles.containerAnimation, leaveFrom: finalStyles.containerTo, leaveTo: finalStyles.containerFrom, children: _jsx("div", { className: twMergeJuan(finalStyles.panel, !isMounted && finalStyles.panel__closed, childOpen && finalStyles.panel__childOpened), ...getFloatingProps(), children: _jsxs("div", { className: finalStyles.panelContent, children: [_jsxs("div", { className: finalStyles.header, children: [_jsx("button", { className: finalStyles.closeButton, "aria-label": t('Megamenu.close'), onClick: handleClose, children: _jsx(SvgSpriteIcon, { icon: "cross", className: finalStyles.closeButtonIcon }) }), logo] }), _jsxs("nav", { id: "megamenuList", className: finalStyles.list, children: [data?.map((single, i) => {
                                                return (_jsx(Fragment, { children: _jsx(MegamenuItem, { level: 0, data: single, sendToParent: setChildHaveChildren }) }, `Megamenu_link_${i}_${single?.primaryKey}`));
                                            }), contentMenu && contentMenu] }), mobileHeader && (_jsx("nav", { id: "megamenuMobileHeader", className: finalStyles.list, children: mobileHeader }))] }) }) })] }) }) }));
};
