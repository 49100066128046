'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import Link from 'next/link';
import { forwardRef } from 'react';
import { useDropdownContext } from './Dropdown';
import { dropdownItemClassNames } from './dropdownItem.styles';
export const DropdownItem = forwardRef(function DropdownItem({ onClick, href, styles, design = 'primary', locale, children, icon, loading, prefetch, ...props }, ref) {
    const finalStyles = dropdownItemClassNames(styles, design);
    const { setOpenId } = useDropdownContext();
    const content = (_jsxs(_Fragment, { children: [icon && _jsx(SvgSpriteIcon, { className: finalStyles.icon, icon: icon }), _jsx("span", { className: finalStyles.content, children: children }), loading && (_jsx(SvgSpriteIcon, { className: finalStyles.loader, icon: "spinner" }))] }));
    if (href) {
        return (_jsx(Link, { href: href, locale: locale, legacyBehavior: true, prefetch: prefetch, children: _jsx("a", { ref: ref, role: 'button', tabIndex: 0, className: finalStyles.container, onClick: () => {
                    setOpenId(undefined);
                }, ...props, children: content }) }));
    }
    else if (onClick) {
        return (_jsx("button", { className: finalStyles.container, onClick: (e) => {
                setOpenId(undefined);
                onClick && onClick(e);
            }, ref: ref, ...props, children: content }));
    }
    return (_jsx("div", { className: finalStyles.container, ref: ref, ...props, children: content }));
});
