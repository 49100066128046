'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { getHref } from '@edeeone/string/getHref';
import { WhispererResult } from '../WhispererResult';
import { useWhispererStore } from '../WhispererStoreClient';
import { ResultSectionEnum } from '../WhispererTypes';
import { WhispererHeading } from './WhispererHeading';
import { WhispererProduct } from './WhispererProduct';
export const ListingProducts = () => {
    const { t } = useI18n('Whisperer');
    const { products: limit } = CONFIG.fulltext.whispererConfig.maxAmountShown;
    const { data, productsCount, linkAll } = useWhispererStore();
    if (!productsCount) {
        return null;
    }
    return (_jsxs(WhispererResult, { design: "primary", children: [_jsx(WhispererHeading, { heading: t('products') || '', count: productsCount, href: getHref(linkAll, {
                    section: ResultSectionEnum.products,
                }) }), data?.edeeFulltextProduct?.recordPage?.data?.map((item, i) => {
                // TODO dodělat limit na úrovni query
                if (i < limit) {
                    return (_jsx("div", { children: _jsx(WhispererProduct, { item: item, last: i === limit - 1 ||
                                i === data.edeeFulltextProduct.recordPage.data.length - 1 }) }, `product-${item?.attributes?.url || i}`));
                }
            })] }));
};
