'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Availability } from '@edeeone/juan-core/components/availability/Availability';
import { Image } from '@edeeone/juan-core/components/image/Image';
import { DiscountTag } from '@edeeone/juan-core/components/price/DiscountTag';
import { Price } from '@edeeone/juan-core/components/price/Price';
import { ProductVariants } from '@edeeone/juan-core/components/productVariants/ProductVariants';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { enumerateAlt } from '@edeeone/juan-core/components/image/utils';
import { emitSelectItemGoogleEvent } from '@edeeone/shop-facebookbusiness/googleTagManager/eventEmitter';
import Link from 'next/link';
import { whispererProductClassNames } from './WhispererProduct.styles';
export const WhispererProduct = ({ styles, last = false, item, }) => {
    const finalStyles = whispererProductClassNames(styles);
    const { t } = useI18n('WhispererProduct');
    // TODO JHR je to správný postup pro získání locale produktu?
    const { locale } = useLocation();
    const prices = {
        allPricesForSale: item?.allPricesForSale,
        priceForSale: item?.priceForSale,
    };
    const motive = item?.attributes?.motive;
    return (_jsxs(Link, { href: item?.attributes?.url, className: twMergeJuan(finalStyles.container, last && finalStyles.container__last), onClick: () => {
            emitSelectItemGoogleEvent({
                name: item?.attributes?.name,
                codeShort: item?.attributes?.codeShort,
                productId: item?.primaryKey,
                // TODO JHR nemělo/nemohlo by být mapování uvnitř emitSelectItemGoogleEvent
                categoryCodes: (item?.categories || []).map((category) => {
                    return category?.referencedEntity?.attributes?.code;
                }),
                totalPrice: {
                    withoutTax: item?.priceForSale?.priceWithoutTax,
                },
                currency: item?.priceForSale?.currency,
                locale: locale,
            });
        }, children: [_jsxs("div", { className: finalStyles.motive, children: [_jsx(Image, { src: motive?.files?.[0]?.src, alt: enumerateAlt(motive?.files?.[0]?.alt, item?.attributes?.name), width: 90, height: 90, aspectRatio: "square", style: {
                            objectFit: 'contain',
                        }, design: "secondary" }), _jsx("div", { className: finalStyles.discountContainer, children: _jsx(DiscountTag, { prices: prices, size: 'sm' }) })] }), _jsxs("div", { className: finalStyles.detail, children: [item?.attributes?.name && (_jsx("h3", { className: twMergeJuan(finalStyles.heading), children: item?.attributes?.name })), _jsx(Availability, { className: finalStyles.stock, stocks: item?.stocks, availability: item?.attributes?.availability }), _jsx(Price, { design: 'secondary', prices: prices, zeroPriceText: t('free'), showFrom: item?.multiplePricesForSaleAvailable }), _jsx("div", { className: finalStyles.variant, children: _jsx(ProductVariants, { items: item?.variants, separator: " ", styles: { container__custom: finalStyles.variant_component } }) })] })] }));
};
