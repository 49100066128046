import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererHeadingDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__result-heading flex text-base w-full',
    container__link: 'cursor-pointer',
    heading: 'text-black font-semibold ',
    heading__link: 'underline hover:no-underline transition-common cursor-pointer hover:no-underline',
    count: 'text-gray-30 ml-3 tracking-wide',
};
export function whispererHeadingClassNames(...args) {
    return getMemoizedFinalClassnames('whispererHeadingClassNamesNew', whispererHeadingDefaultClassNames, ...args);
}
