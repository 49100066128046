import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from '../tag/Tag';
import { calculateDiscountPercent } from './Price.utils';
export const DiscountTag = function ({ prices, size = 'xl', styles, }) {
    const discountAmount = calculateDiscountPercent(prices?.priceForSale?.priceWithTax, prices?.allPricesForSale?.[0]?.accompanyingPrice?.priceWithTax);
    if (discountAmount == null || discountAmount <= 0) {
        return null;
    }
    return (_jsx(Tag, { design: 'danger', size: size, styles: styles, children: `−${discountAmount} %` }));
};
