import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const headerCartDefaultClassNames = /* tw */ {
    button: 'flex gap-8',
    buttonIcon: 'relative block',
    label: 'label label--header',
    icon: 'ico ico--header',
    price: 'hidden lg:block',
    heading: 'flex items-center',
    emptyContainer: 'px-6 flex flex-col gap-10',
    emptyContainer_text: 'text-xs text-gray-60',
    itemsContainer: 'max-h-[185px] overflow-y-auto custom-scrollbar px-8',
    items: 'flex flex-col gap-10 items-center',
    footerLink: 'flex',
};
export function headerCartClassNames(...args) {
    return getMemoizedFinalClassnames('headerCartClassNames', headerCartDefaultClassNames, ...args);
}
