'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '@edeeone/juan-core/components/badge/Badge';
import { Dropdown, useDropdownContext, } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { Price } from '@edeeone/juan-core/components/price/Price';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { HeaderCartDropdownContent } from '@edeeone/shop-orders/components/headerCart/HeaderCartDropdownContent';
import { HeaderCartDropdownFooter } from '@edeeone/shop-orders/components/headerCart/HeaderCartDropdownFooter';
import { useUserProfileCart } from '@edeeone/shop-orders/hooks/useUserProfileCart';
import { getCartProductCount } from '@edeeone/shop-orders/utils';
import { headerCartClassNames } from './HeaderCart.styles';
export const HeaderCart = ({ styles = CONFIG?.componentSettings?.shopOrders?.HeaderCart?.styles, showTotalProductCount = CONFIG?.componentSettings?.shopOrders?.HeaderCart
    ?.showTotalProductCount ?? false, showOverlay = CONFIG?.componentSettings?.shopOrders?.HeaderCart
    ?.showOverlay ?? false, showLabel = CONFIG?.componentSettings?.shopOrders?.HeaderCart?.showLabel ??
    false, }) => {
    const cart = useUserProfileCart();
    const finalStyles = headerCartClassNames(styles);
    const { t } = useI18n('HeaderCart');
    const cartPath = useLinkRoute({
        id: '/shop-orders/cart',
    });
    const cartItems = cart?.data?.items || [];
    const isCartEmpty = cartItems.length === 0;
    const cartTotalCount = getCartProductCount(cartItems, showTotalProductCount);
    const { openId } = useDropdownContext();
    const id = 'headerCart';
    const isOpen = openId === id;
    return (_jsx(Dropdown, { href: cartPath?.url, showOverlay: showOverlay, openOnHover: true, id: id, button: _jsxs("span", { className: finalStyles.button, children: [_jsxs("span", { className: finalStyles.buttonIcon, children: [_jsx(SvgSpriteIcon, { icon: "headerShoppingCart", className: finalStyles.icon }), !isCartEmpty && (_jsx(Badge, { size: "sm", display: "positioned", border: true, children: cartTotalCount }))] }), showLabel && isCartEmpty && (_jsx("span", { className: finalStyles.label, children: t('cart') })), !isCartEmpty && (_jsx(HeaderCartPrice, { cart: cart, styles: { container__custom: finalStyles.price } }))] }), buttonLabel: t('cart'), heading: isCartEmpty ? (t('empty')) : (_jsxs("div", { className: finalStyles.heading, children: [t('nonEmpty', {
                    count: cartTotalCount,
                }), "\u00A0", _jsx(HeaderCartPrice, { cart: cart })] })), footer: (isOpen && !isCartEmpty && _jsx(HeaderCartDropdownFooter, {})) || undefined, children: isOpen && _jsx(HeaderCartDropdownContent, { styles: styles }) }));
};
const HeaderCartPrice = ({ styles, cart }) => {
    const { t } = useI18n('HeaderCart');
    const { currency } = useLocation();
    return (_jsx(Price, { zeroPriceText: t('free'), styles: styles, design: 'secondary', prices: {
            priceForSale: {
                priceWithoutTax: cart?.data?.totalPriceOfItemsWithoutPolicies?.withoutTax,
                priceWithTax: cart?.data?.totalPriceOfItemsWithoutPolicies?.withTax,
                currency,
            },
            allPricesForSale: [],
        } }));
};
