import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const headerRegistrationDefaultClassNames = /* tw */ {
    item: 'text-gray-70 group-hover/item:text-black group-hover/item:underline',
    button: 'relative block',
    buttonIcon: 'relative block',
    label: 'label label--header',
    icon: 'ico ico--header',
    heading: 'flex flex-col gap-1 items-center',
    headingUser: 'dropdown-header__name',
    headingCompany: 'dropdown-header__company font-normal text-xxs text-gray-60',
};
export function headerRegistrationClassNames(...args) {
    return getMemoizedFinalClassnames('headerRegistrationClassNames', headerRegistrationDefaultClassNames, ...args);
}
