'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMegamenuContext } from '@edeeone/juan-core/components/megamenu/useMegamenu';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import Link from 'next/link';
import { profileMenuClassNames } from './ProfileMenu.styles';
export const MenuItem = ({ icon, linkText, href, badge, children, active = false, design, styles, showActiveLink, currentMenuItemUrl, }) => {
    const route = useLinkRoute({
        id: href,
    });
    const finalStyles = profileMenuClassNames(styles, design);
    currentMenuItemUrl = currentMenuItemUrl || '/';
    const { close } = useMegamenuContext();
    const { domain } = useLocation();
    const currentUrl = currentMenuItemUrl.replace(domain, '');
    return (_jsxs(Link, { href: route?.url || '/', className: twMergeJuan(finalStyles.link_container, showActiveLink &&
            route?.url?.endsWith(currentUrl) &&
            finalStyles.link_container__active), onClick: () => {
            close();
        }, children: [_jsx(SvgSpriteIcon, { icon: icon, ariaHidden: true, className: twMergeJuan(finalStyles.link_icon, showActiveLink &&
                    route?.url?.endsWith(currentUrl) &&
                    finalStyles.link_icon__active) }), _jsx("span", { className: finalStyles.link_text, children: linkText }), badge && _jsxs("span", { className: finalStyles.link_badge, children: ["\u00A0(", badge, ")"] }), children] }));
};
