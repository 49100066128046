import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const profileMenuDefaultClassNames = /* tw */ {
    group: 'profile-menu__group',
    groupTitle: 'profile-menu__title',
    groupSubtitle: 'profile-menu__subtitle pb-6 mb-8 border-b border-b-gray-20',
    groupContent: 'profile-menu__content flex flex-col gap-9 py-8 mb-8',
    groupProfile: 'profile-menu__group--profile',
    groupProfileTitle: 'mb-0',
    email: 'profile-menu__email text-xs text-gray-60',
    company: 'profile-menu__company text-xs text-gray-60',
    groupLinks: 'profile-menu__group--links',
    groupLists: 'profile-menu__group--lists',
    groupMyAccount: 'profile-menu__group--my-account',
    groupLogout: 'profile-menu__group--logout pt-8 border-t border-t-gray-20',
    groupProfileContent: '',
    groupLinksContent: '',
    groupListsContent: '',
    groupMyAccountContent: 'mb-0',
    contentGroups: 'profile-menu__content-groups w-full',
    link_container: 'profile-menu__item flex items-center group/link text-sm text-gray-70 cursor-pointer transition-common',
    link_container__active: 'font-semibold text-black',
    link_icon: 'profile-menu__ico ico w-8 h-8 mr-6 shrink-0 fill-gray-30 group-hover/link:fill-black !transition-none',
    link_icon__active: 'fill-black',
    link_text: 'profile-menu__text group-hover/link:underline group-hover/link:text-black',
    link_badge: 'profile-menu__badge text-gray-30',
    link_credit: 'font-semibold text-black',
    // menu v layoutu sekce Můj účet
    primary: {
        contentGroups: 'md:flex md:flex-wrap md:max-h-[65rem] md:flex-col md:gap-12 lg:max-h-none lg:flex lg:flex-row xl:block',
        groupProfile: 'w-full',
        groupProfileContent: 'hidden xl:block',
        groupLinks: 'lg:w-[calc(33%_-_1.5rem)] xl:w-full',
        groupLists: 'lg:w-[calc(33%_-_1.5rem)] xl:w-full',
        groupMyAccount: 'lg:w-[calc(32%_-_1.5rem)] xl:w-full',
        groupLogout: 'w-full',
    },
    // menu v RWD menu
    secondary: {},
};
export function profileMenuClassNames(...args) {
    return getMemoizedFinalClassnames('profileMenuClassNames', profileMenuDefaultClassNames, ...args);
}
