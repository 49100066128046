import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const headerUserDefaultClassNames = /* tw */ {
    content: 'px-6 flex flex-col gap-10',
    user: 'font-semibold',
    item: 'text-gray-70 group-hover/item:text-black group-hover/item:underline',
    item_badge: 'text-gray-30',
    credit: 'font-semibold text-black',
    separator: 'border-gray-20',
};
export function headerUserClassNames(...args) {
    return getMemoizedFinalClassnames('headerUserClassNames', headerUserDefaultClassNames, ...args);
}
