'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '@edeeone/juan-core/components/badge/Badge';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { Dropdown } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useProductComparison } from '@edeeone/shop-productcomparison/hooks/useProductComparison';
import { headerComparisonClassNames } from './HeaderComparison.styles';
import { HeaderComparisonItem } from './HeaderComparisonItem';
export const HeaderComparison = ({ styles = CONFIG?.componentSettings?.shopProductComparison?.HeaderComparison
    ?.styles, showOverlay = CONFIG?.componentSettings?.shopProductComparison
    ?.HeaderComparison?.showOverlay ?? false, showLabel = CONFIG?.componentSettings?.shopProductComparison?.HeaderComparison
    ?.showLabel ?? false, }) => {
    const finalStyles = headerComparisonClassNames(styles);
    const { t } = useI18n('HeaderComparison');
    const { productComparisonItems, productComparisonCount, isProductComparisonEmpty, } = useProductComparison();
    const route = useLinkRoute({
        id: '/shop-productComparison/ProductComparison',
    });
    return (_jsx(Dropdown, { hideHeaderBorder: isProductComparisonEmpty, showOverlay: showOverlay, openOnHover: true, href: route?.url, button: _jsxs("span", { className: finalStyles.button, children: [_jsxs("span", { className: finalStyles.buttonIcon, children: [_jsx(SvgSpriteIcon, { icon: "headerChart", className: finalStyles.icon }), !isProductComparisonEmpty && (_jsx(Badge, { size: "sm", display: "positioned", border: true, children: productComparisonCount }))] }), showLabel && (_jsx("span", { className: finalStyles.label, children: t('comparisonLabel') }))] }), buttonLabel: t('comparisonLabel'), heading: isProductComparisonEmpty ? t('empty') : t('comparison'), footer: !isProductComparisonEmpty ? (_jsx(Button, { href: route?.url, design: "primary", children: t('show') })) : undefined, children: !isProductComparisonEmpty && (_jsx("div", { className: finalStyles.container, children: _jsx(ItemList, { data: productComparisonItems }) })) }));
};
export const ItemList = ({ data, styles }) => {
    const finalStyles = headerComparisonClassNames(styles);
    return (_jsx("ul", { className: finalStyles.items, children: data?.map((product) => {
            return (_jsx(HeaderComparisonItem, { productId: product.primaryKey, name: product?.attributes?.name || '', url: product?.attributes?.url || '', prices: { priceForSale: product.priceForSale }, motive: product?.attributes?.motive }, product.primaryKey));
        }) }));
};
