import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const radioGroupDefaultClassNames = /* tw */ {
    group: 'form-group form-group--radio-group flex flex-col',
    group__custom: '',
    groupLabel: 'form-group__group-label inline-block text-xs text-black font-medium mb-2',
    groupLabel__custom: '',
    container: 'form-group__item flex items-start group/radio',
    container__custom: '',
    content: 'form-group__content relative h-[2.2rem] w-[2.2rem] mr-4 flex shrink-0 items-center',
    input: 'form-group__input absolute outline-none appearance-none h-[2.2rem] w-[2.2rem] border-radio checked:bg-black group-[.checked]/radio:bg-black radius-radio bg-white cursor-pointer peer transition-common',
    input__custom: '',
    label: 'form-group__label inline-block text-sm font-normal cursor-pointer',
    label__checked: 'font-medium',
    labelPrefix: 'form-group__label-prefix text-s text-gray-60 mr-3',
    labelSuffix: 'form-group__label-suffix text-s text-gray-60 ml-3',
    icon: 'absolute hidden pointer-events-none peer-checked:block group-[.checked]/radio:block fill-white w-4 h-4 mx-[.6rem]',
    group__error: 'form-group--error',
    label__error: 'text-error',
    error: 'form-group__error mt-2 text-xs text-red text-left',
    errorField: 'inline-block font-semibold',
    // výchozí radioGroup
    primary: {
        group: 'gap-8',
    },
    // radioGroup v langSwitch komponentě
    secondary: {
        group: 'gap-10',
        label: 'text-gray-70',
        label__checked: 'font-medium text-black',
    },
    // radioGroup v košíku (výběr platby a doprava)
    tertiary: {
        container: 'pointer-events-none',
        content: 'mr-0',
    },
};
export function radioGroupClassNames(...args) {
    return getMemoizedFinalClassnames('radioGroupClassNames', radioGroupDefaultClassNames, ...args);
}
