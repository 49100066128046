import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererDefaultClassNames = /* tw */ {
    container: 'whisperer relative w-auto h-[54px] lg:max-w-[761px] sm:max-w-[500px] sm:w-full',
    container__active: 'z-50',
    modalToggleContainer: '',
    modalToggle: 'fulltext-autocomplete__toggle w-[2.8rem] h-[2.8rem]',
    // TODO tohle by mělo být součást modalu ne whispereru. předává se tam přeci design
    modalContainer: '!shadow-none',
};
export function whispererClassNames(...args) {
    return getMemoizedFinalClassnames('whispererClassNamesNew', whispererDefaultClassNames, ...args);
}
