import { jsx as _jsx } from "react/jsx-runtime";
const visibleBreakpointClasses = {
    sm: 'sm:flex',
    md: 'md:flex',
    lg: 'lg:flex',
    xl: 'xl:flex',
    '2xl': '2xl:flex',
};
const hiddenBreakpointClasses = {
    sm: 'sm:hidden',
    md: 'md:hidden',
    lg: 'lg:hidden',
    xl: 'xl:hidden',
    '2xl': '2xl:hidden',
};
export const ResponsiveDisplay = ({ breakpoint = 'sm', visible, children, }) => {
    const hiddenClass = visible ? 'hidden' : '';
    const visibilityClass = visible
        ? `${visibleBreakpointClasses[breakpoint]} flex-auto`
        : hiddenBreakpointClasses[breakpoint];
    return _jsx("div", { className: `${hiddenClass} ${visibilityClass}`, children: children });
};
