import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererProductDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__item max-w-full gap-6 flex w-full group/item pb-6 border-b border-gray-20 cursor-pointer',
    container__active: '',
    container__last: 'pb-0 border-b-0',
    motive: 'fulltext-autocomplete__item-motive w-36 h-36 relative block flex-shrink-0',
    detail: 'fulltext-autocomplete__item-content inline-flex flex-col justify-center',
    heading: 'fulltext-autocomplete__item-heading text-gray-70 text-sm font-medium transition-common group-hover/item:underline',
    heading__active: 'underline',
    stock: 'fulltext-autocomplete__item-stock text-xs',
    variant: 'fulltext-autocomplete__item-variant mt-2',
    variant_component: '!my-0',
    discountContainer: 'fulltext-autocomplete__item-discount absolute top-0 right-0',
};
export function whispererProductClassNames(...args) {
    return getMemoizedFinalClassnames('whispererProductClassNamesNew', whispererProductDefaultClassNames, ...args);
}
