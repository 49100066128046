'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMegamenuExternalControl } from '@edeeone/juan-core/components/megamenu/MegamenuExternalControl';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { menuToggleClassNames } from '@edeeone/juan-core/components/header/MenuToggle.styles';
import { Button } from '@edeeone/juan-core/components/button/Button';
export const MenuToggle = ({ buttonDesign = CONFIG?.componentSettings?.juanCore?.MenuToggle
    ?.buttonDesign ?? 'tertiary', buttonSize = CONFIG?.componentSettings?.juanCore?.MenuToggle?.buttonSize ??
    'md', }) => {
    const { setOpen, open } = useMegamenuExternalControl();
    const finalStyles = menuToggleClassNames();
    const { t } = useI18n('MenuToggle');
    return (_jsxs(Button, { design: buttonDesign, size: buttonSize, onClick: () => {
            try {
                setOpen(!open);
            }
            catch (error) {
                console.error('chybí context provider pro externí ovládání menu');
            }
        }, styles: { button__custom: finalStyles.btn }, ariaLabel: t('MenuToggle.label'), "aria-haspopup": "true", "aria-expanded": "false", "aria-controls": "layoutHeaderMegamenu", children: [_jsx(SvgSpriteIcon, { icon: "menu", className: finalStyles.btn_ico }), _jsx("span", { className: finalStyles.btn_label, children: t('MenuToggle.label') })] }));
};
