'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { Checkbox } from '../form/checkbox/Checkbox';
import { useField } from '../form/field';
import { FormGroup } from '../form/formGroup/FormGroup';
import { cookiePreferenceSectionClassNames } from './cookiePreferenceSection.styles';
export const CookiePreferenceSection = ({ type, forced, styles, design = 'primary' }) => {
    const { t } = useI18n('CookiePreferencesForm');
    const finalStyles = cookiePreferenceSectionClassNames(styles, design);
    const { value } = useField({
        name: type,
    });
    return (_jsxs("div", { className: finalStyles.container, children: [_jsxs("div", { className: finalStyles.headingSection, children: [_jsx("h3", { className: finalStyles.heading, children: t(type?.toLowerCase()) }), forced && (_jsx("p", { className: finalStyles.accepted, children: t('allwaysAccepted') })), !forced && (_jsx(FormGroup, { name: type, showLabel: false, styles: {
                            group: finalStyles.toggle,
                        }, children: _jsx(Checkbox, { checkedValue: true, undefinedValue: false, name: type, design: "septenary", label: t(value ? 'accepted' : 'rejected'), disabled: forced }) }))] }), _jsx("p", { className: finalStyles.description, children: t(`${type?.toLowerCase()}Description`) })] }));
};
