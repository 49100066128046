export const getCartProductCount = (items, showTotalProductCount) => {
    return showTotalProductCount
        ? items.reduce((acc, it) => {
            return acc + (it?.quantity || 0);
        }, 0)
        : items.length;
};
export const sortAndGroupPricePolicies = (pricePolicies) => {
    const usedCredits = [];
    const usedVouchers = [];
    const usedGiftCards = [];
    const restPricePolicies = [];
    const positivePricePolicies = [];
    const negativePricePolicies = [];
    const defaultIgnoredPolicyTypes = [
        'totalOrderShippingPricePolicy',
        'tagRuleShippingPricePolicy',
    ];
    const projectIgnoredPolicyTypes = 
    // @ts-ignore
    CONFIG?.componentSettings?.Cart?.pricePolicyTypesWithoutMessage ?? [];
    const ignoredPolicyTypes = [
        ...defaultIgnoredPolicyTypes,
        ...projectIgnoredPolicyTypes,
    ];
    pricePolicies?.forEach((pp) => {
        if (pp.hidden) {
            if (!ignoredPolicyTypes.includes(pp.pricePolicyType)) {
                console.warn(`Price policy "${pp.pricePolicyType}" has been skipped because of missing message`);
            }
        }
        else if (pp.pricePolicyType === 'credit') {
            usedCredits.push(pp);
        }
        else if (pp.pricePolicyType === 'voucher') {
            usedVouchers.push(pp);
            if (pp.calculationStrategy === 'PAYMENT') {
                usedGiftCards.push(pp);
            }
        }
        else {
            restPricePolicies.push(pp);
            if (parseFloat(pp.negateAbsoluteAmount) < 0) {
                negativePricePolicies.push(pp);
            }
            else {
                positivePricePolicies.push(pp);
            }
        }
    });
    const usedGiftCardsAmount = usedGiftCards.reduce((acc, c) => {
        return acc + parseFloat(c?.negateAbsoluteAmount || 0);
    }, 0);
    return {
        usedCredits,
        usedVouchers,
        usedGiftCards,
        restPricePolicies,
        positivePricePolicies,
        negativePricePolicies,
        usedGiftCardsAmount,
    };
};
export const getValidPersistentMessages = (persistentMessages, validCodes) => {
    return persistentMessages.filter((m) => {
        if (validCodes.length > 0 && !validCodes.includes(m.code)) {
            return false;
        }
        return !m.closed;
    });
};
