import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, cloneElement, isValidElement } from 'react';
export const Highlight = ({ text, search = '', caseSensitive = false, as, searchAs, }) => {
    const rgx = new RegExp(search, caseSensitive ? 'g' : 'gi');
    let split = text.split(rgx);
    return (_jsx(_Fragment, { children: cloneElement(isValidElement(as) ? as : _jsx("p", {}), {
            children: (_jsx(_Fragment, { children: split?.map((single, i) => {
                    return (_jsxs(Fragment, { children: [single, i !== split.length - 1 && (_jsx(_Fragment, { children: _jsx(HighlightSearch, { search: text.match(rgx)?.[0], searchAs: searchAs }) }))] }, `highlight-${single}-${i}`));
                }) })),
        }) }));
};
export const HighlightSearch = ({ search = '', searchAs, }) => {
    return (_jsx(_Fragment, { children: cloneElement(isValidElement(searchAs) ? searchAs : _jsx("mark", {}), {
            children: _jsx(_Fragment, { children: search }),
        }) }));
};
