'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { whispererContainerClassNames } from './WhispererContainer.styles';
import { WhispererInput } from './WhispererInput';
import { WhispererResults } from './WhispererResults';
import { useWhispererStore } from './WhispererStoreClient';
export const WhispererContainer = () => {
    const finalStyles = whispererContainerClassNames();
    const { opened, setOpened, inPortal, containerRegister } = useWhispererStore();
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, opened && finalStyles.container__open, opened && finalStyles.container__active), children: [opened && (_jsx(_Fragment, { children: _jsx("div", { className: twMergeJuan(finalStyles.overlay), onClick: (e) => {
                        setOpened(false);
                        e.stopPropagation();
                    } }) })), _jsxs("div", { ...containerRegister, children: [_jsx("div", { className: twMergeJuan(finalStyles.inputContainer, opened && finalStyles.inputContainer__active), children: _jsx(WhispererInput, { showLoader: true }) }), opened && _jsx(WhispererResults, {}), opened && (_jsx("button", { type: 'button', className: finalStyles.closeBtn, onClick: (e) => {
                            setOpened(false);
                            e.stopPropagation();
                        }, children: _jsx(SvgSpriteIcon, { icon: "cross", className: finalStyles.closeIcon }) })), _jsx("button", { className: finalStyles.toggleContainer, type: 'button', children: _jsx(SvgSpriteIcon, { icon: "headerMagnifier", className: finalStyles.toggle }) })] })] }));
};
