import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { formatCurrency } from '@edeeone/juan-core/utils/formatCurrency';
import { shippingDiscountClassNames } from './shippingDiscount.styles';
export const ShippingDiscountBase = ({ styles, shippingDiscount, }) => {
    const finalStyles = shippingDiscountClassNames(styles);
    const { locale, currency } = useLocation();
    const { t } = useI18n('ShippingDiscount');
    if (shippingDiscount?.progressBar === null) {
        return null;
    }
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsx(SvgSpriteIcon, { className: finalStyles.icon, icon: "truck", "aria-hidden": false }), _jsx("div", { className: finalStyles.text, children: shippingDiscount?.progressBar !== 100 ? (_jsx(_Fragment, { children: t('inProgress', {
                        value: formatCurrency(shippingDiscount?.remainingValueToDiscount, currency, {
                            locales: locale,
                        }),
                        // NOTE: not add key to strong tag. React show warning with key problems, but it's not necessary in this case and is buggy in this case. Dom in not updated correctly. I don't know why and how to fix it correctly.
                        strong: (chunks) => {
                            return _jsx("strong", { className: finalStyles.text_b, children: chunks });
                        },
                    }) })) : (_jsx(_Fragment, { children: t('done', {
                        strong: (chunks) => {
                            return _jsx("strong", { className: finalStyles.text_b, children: chunks });
                        },
                    }) })) })] }));
};
