import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const listingSearchHistoryDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete__column-other w-full md:w-[calc(50%_-_1.5rem)] flex flex-wrap flex-col gap-12 justify-start empty:hidden order-1 sm:order-2',
    content: 'fulltext-autocomplete__result--search-history',
    headingContainer: 'w-full',
    resetBtn: 'fulltext-autocomplete__result-reset flex max-w-full font-normal text-xs underline hover:no-underline transition-common w-full cursor-pointer',
};
export function listingSearchHistoryClassNames(...args) {
    return getMemoizedFinalClassnames('listingSearchHistoryClassNamesNew', listingSearchHistoryDefaultClassNames, ...args);
}
