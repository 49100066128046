import { useTrackingRecentlyVisitedQuery } from '../graphql/tracking.codegen';
const defaultValue = {
    trackingRecentlyVisited: [],
    count: 0,
};
export const useTrackingRecentlyVisited = function (input) {
    const { data } = useTrackingRecentlyVisitedQuery({
        variables: {
            input,
        },
        context: {
            tags: ['recentlyVisited', 'userProfile'],
        },
    });
    const items = data?.trackingRecentlyVisited?.recordStrip?.data;
    return data?.trackingRecentlyVisited
        ? {
            trackingRecentlyVisited: items,
            count: items?.length || 0,
        }
        : defaultValue;
};
