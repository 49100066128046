import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const CompanyDetailOfCurrentUserDocument = gql `
    query companyDetailOfCurrentUser {
  companyDetailOfCurrentUser {
    approved
    approvedOn
    companyAddress {
      city
      cityPart
      companyName
      countryCode
      created
      email
      fullName
      id
      main
      modified
      note
      phone
      postCode
      regNumber
      removesAddress
      replacesAddress
      status
      street
      taxRegNumber
      type
      used
      vatRegNumber
    }
    countryCode
    created
    createdByPhone
    id
    modified
    name
    regNumber
    taxRegNumber
    vatRegNumber
  }
}
    `;
/**
 * __useCompanyDetailOfCurrentUserQuery__
 *
 * To run a query within a React component, call `useCompanyDetailOfCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDetailOfCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDetailOfCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyDetailOfCurrentUserQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(CompanyDetailOfCurrentUserDocument, options);
}
export function useCompanyDetailOfCurrentUserLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(CompanyDetailOfCurrentUserDocument, options);
}
export function useCompanyDetailOfCurrentUserSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(CompanyDetailOfCurrentUserDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_CompanyDetailOfCurrentUserQuery = async function (options) {
    return getGqlData({
        ...options,
        query: CompanyDetailOfCurrentUserDocument,
    });
};
