'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { whispererInputClassNames } from './WhispererInput.styles';
import { useWhispererInputRegister, useWhispererStore, } from './WhispererStoreClient';
export const WhispererInput = ({ placeholder = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.placeholder, canEmpty = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput?.canEmpty, showLoader = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.showLoader, decoratorIcon = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.decoratorIcon ?? 'magnifier', spinnerIcon = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.spinnerIcon ?? 'spinner', resetIcon = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.resetIcon ?? 'cross', submitMobileIcon = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.submitMobileIcon ?? 'magnifier', submitDesktopIcon = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.submitDesktopIcon ?? 'magnifier', submitDesign = CONFIG?.componentSettings?.edeeFulltext?.WhispererInput
    ?.submitDesign ?? 'tertiary', }) => {
    const finalStyles = whispererInputClassNames();
    const { t } = useI18n('Whisperer');
    const { searchQuery, setSearchQuery, loading, canSubmit, opened } = useWhispererStore();
    const inputRegister = useWhispererInputRegister();
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, opened && finalStyles.container__active), children: [_jsx(SvgSpriteIcon, { icon: decoratorIcon, className: finalStyles.icon }), _jsx("input", { type: "text", value: searchQuery, placeholder: placeholder || t('defaultPlaceholder'), className: finalStyles.input, ...inputRegister }), showLoader && loading && (_jsx(SvgSpriteIcon, { icon: spinnerIcon, className: finalStyles.loader })), canEmpty && !!searchQuery?.length && (_jsx("button", { type: "button", onClick: () => {
                    setSearchQuery('');
                }, children: _jsx(SvgSpriteIcon, { icon: resetIcon, className: twMergeJuan(finalStyles.icon, 'reset') }) })), _jsx("button", { className: finalStyles.submitMobile, type: "submit", children: _jsx(SvgSpriteIcon, { icon: submitMobileIcon, className: twMergeJuan(finalStyles.icon, finalStyles.submitMobileIcon) }) }), _jsx("span", { className: finalStyles.submit, children: _jsx(Button, { type: "submit", design: submitDesign, disabled: !canSubmit, icon: submitDesktopIcon, styles: {
                        button__custom: finalStyles.submit_btn,
                        content__custom: finalStyles.submit_content,
                        icon__custom: finalStyles.submit_ico,
                    }, children: t('search') }) })] }));
};
