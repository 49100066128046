'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getActiveWeb } from '@edeeone/juan-core/routes/utils/getActiveWeb';
import { getHref } from '@edeeone/string/getHref';
import { WhispererResult } from '../WhispererResult';
import { useWhispererStore } from '../WhispererStoreClient';
import { listingBlogClassNames } from './ListingBlog.styles';
import { WhispererHeading } from './WhispererHeading';
import { WhispererItem } from './WhispererItem';
export const ListingBlog = () => {
    const finalStyles = listingBlogClassNames();
    const { t } = useI18n('Whisperer');
    const { data, linkAll } = useWhispererStore();
    const { webId } = useLocation();
    const activeWeb = getActiveWeb(webId);
    const whispererHiddenSections = activeWeb.fulltext?.whispererHiddenSections || [];
    const notEmptySectionsWithoutHidden = data?.edeeFulltextStageMultiSection?.filter((it) => {
        return (!!it?.data?.totalNumberOfRecords &&
            !whispererHiddenSections.includes(it?.section));
    });
    const isVisible = !!notEmptySectionsWithoutHidden?.length;
    if (!isVisible) {
        return null;
    }
    const { news: limit } = CONFIG.fulltext.whispererConfig.maxAmountShown;
    return (_jsx(_Fragment, { children: notEmptySectionsWithoutHidden?.map((section, i) => {
            return (_jsxs(WhispererResult, { design: "secondary", styles: { container__custom: finalStyles.container }, children: [_jsx("div", { className: finalStyles.headingContainer, children: _jsx(WhispererHeading, { heading: t(`section.${section?.section}`), count: section?.data?.totalNumberOfRecords, href: getHref(linkAll, {
                                section: section?.section,
                            }) }) }), section?.data?.data?.map((single, i) => {
                        if (i < limit)
                            return (_jsx("div", { children: _jsx(WhispererItem, { href: single?.uri, heading: single?.title, design: "secondary" }) }, `section-${i}-${single?.uri || i}`));
                    })] }, `section-${section?.section}`));
        }) }));
};
