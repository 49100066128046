import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const FieldsPriceFragmentDoc = gql `
    fragment fieldsPrice on PriceForSale {
  priceWithTax
  priceWithoutTax
  currency
  validity
}
    `;
export const ProductPricesFieldsFragmentDoc = gql `
    fragment productPricesFields on Product {
  multiplePricesForSaleAvailable(currency: $currency)
  priceForSale(validNow: true, currency: $currency) {
    ...fieldsPrice
  }
  allPricesForSale(priceLists: $priceLists, currency: $currency) {
    accompanyingPrice(priceLists: $priceLists) {
      priceWithTax
      priceWithoutTax
      currency
      validity
    }
    ...fieldsPrice
  }
  attributes(locale: $locale) {
    saleEnded
  }
}
    ${FieldsPriceFragmentDoc}`;
export const AvailabilityFieldsFragmentDoc = gql `
    fragment availabilityFields on Product {
  stocks {
    attributes {
      quantityOnStock
    }
    referencedEntity {
      attributes(locale: $locale) {
        name
        code
      }
    }
  }
  attributes(locale: $locale) {
    availability
  }
}
    `;
export const ImageFieldsFragmentDoc = gql `
    fragment imageFields on RelatedFiles {
  fileIds
  files {
    src
    alt
  }
}
    `;
export const RecordStripFieldsFragmentDoc = gql `
    fragment recordStripFields on ProductRecordStrip {
  first
  empty
  limit
  totalRecordCount
  offset
  data {
    primaryKey
    associatedData {
      localization
    }
    categories {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
        }
      }
    }
    relatedProducts {
      attributes {
        category
      }
    }
    variants {
      referencedEntity {
        attributes {
          codeShort
        }
      }
    }
    ...productPricesFields
    ...availabilityFields
    attributes(locale: $locale) {
      name
      descriptionShort
      codeShort
      url
      productType
      ratingVotes
      rating
      motive: relatedFiles(category: "hlavni-motiv") {
        ...imageFields
      }
      minOrderQuantity
      saleEnded
    }
    master {
      referencedPrimaryKey
    }
    tags {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          isVisibleInDetail
          isVisibleInFilter
          isVisibleInListing
        }
        type
      }
    }
    parameterValues(
      filterBy: {inScope: {scope: LIVE, filtering: {attributeVariantEquals: true}}}
    ) {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          order
        }
        parameter {
          attributes {
            orderInParameter
          }
          referencedEntity {
            attributes {
              name
              code
              isVisibleInDetail
            }
          }
        }
      }
    }
  }
}
    ${ProductPricesFieldsFragmentDoc}
${AvailabilityFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const TrackingVisitDocument = gql `
    mutation trackingVisit($input: TrackingInput) {
  trackingVisit(input: $input) {
    success
    message
  }
}
    `;
/**
 * __useTrackingVisitMutation__
 *
 * To run a mutation, you first call `useTrackingVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackingVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackingVisitMutation, { data, loading, error }] = useTrackingVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackingVisitMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(TrackingVisitDocument, options);
}
export const TrackingDeleteDocument = gql `
    mutation trackingDelete {
  trackingDelete {
    success
    message
  }
}
    `;
/**
 * __useTrackingDeleteMutation__
 *
 * To run a mutation, you first call `useTrackingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackingDeleteMutation, { data, loading, error }] = useTrackingDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useTrackingDeleteMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(TrackingDeleteDocument, options);
}
export const TrackVisitDocument = gql `
    query trackVisit($input: TrackingInput) {
  trackVisit(input: $input) {
    success
    message
  }
}
    `;
/**
 * __useTrackVisitQuery__
 *
 * To run a query within a React component, call `useTrackVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackVisitQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackVisitQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(TrackVisitDocument, options);
}
export function useTrackVisitLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(TrackVisitDocument, options);
}
export function useTrackVisitSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(TrackVisitDocument, options);
}
export const TrackingVisitorsDocument = gql `
    query trackingVisitors($input: TrackingInput) {
  trackingVisitors(input: $input) {
    visitorsCount
  }
}
    `;
/**
 * __useTrackingVisitorsQuery__
 *
 * To run a query within a React component, call `useTrackingVisitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingVisitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingVisitorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackingVisitorsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(TrackingVisitorsDocument, options);
}
export function useTrackingVisitorsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(TrackingVisitorsDocument, options);
}
export function useTrackingVisitorsSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(TrackingVisitorsDocument, options);
}
export const TrackingRecentlyVisitedDocument = gql `
    query trackingRecentlyVisited($input: TrackingInput, $locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"], $limit: Int = 999) {
  trackingRecentlyVisited(input: $input) {
    recordStrip(offset: 0, limit: $limit) {
      ...recordStripFields
    }
  }
}
    ${RecordStripFieldsFragmentDoc}`;
/**
 * __useTrackingRecentlyVisitedQuery__
 *
 * To run a query within a React component, call `useTrackingRecentlyVisitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingRecentlyVisitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingRecentlyVisitedQuery({
 *   variables: {
 *      input: // value for 'input'
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTrackingRecentlyVisitedQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(TrackingRecentlyVisitedDocument, options);
}
export function useTrackingRecentlyVisitedLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(TrackingRecentlyVisitedDocument, options);
}
export function useTrackingRecentlyVisitedSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(TrackingRecentlyVisitedDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_TrackVisitQuery = async function (options) {
    return getGqlData({
        ...options,
        query: TrackVisitDocument,
    });
};
export const getData_TrackingVisitorsQuery = async function (options) {
    return getGqlData({
        ...options,
        query: TrackingVisitorsDocument,
    });
};
export const getData_TrackingRecentlyVisitedQuery = async function (options) {
    return getGqlData({
        ...options,
        query: TrackingRecentlyVisitedDocument,
    });
};
