'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DropdownItem } from '@edeeone/juan-core/components/dropdown/DropdownItem';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { LinkRoute } from '@edeeone/juan-core/routes/LinkRoute';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { headerCartClassNames, } from '@edeeone/shop-orders/components/headerCart/HeaderCart.styles';
import { HeaderCartList } from '@edeeone/shop-orders/components/headerCart/HeaderCartList';
import { useUserProfileCart } from '@edeeone/shop-orders/hooks/useUserProfileCart';
import { useTrackingRecentlyVisited } from '@edeeone/shop-tracking/hooks/useTrackingRecentlyVisited';
import { useWishlist } from '@edeeone/shop-wishlist/hooks/useWishlist';
export const HeaderCartDropdownContent = ({ styles }) => {
    const cart = useUserProfileCart();
    const tracking = useTrackingRecentlyVisited();
    const trackingCount = tracking?.count || 0;
    const finalStyles = headerCartClassNames(styles);
    const { t } = useI18n('HeaderCart');
    const wishlistPath = useLinkRoute({
        id: '/shop-wishlist/Wishlist',
    });
    const cartItems = cart?.data?.items;
    const isCartEmpty = !cartItems?.length;
    const { isWishlistEmpty, wishlistCount } = useWishlist();
    const showWishlistItem = CONFIG.shop.customerProductList.wishlist && !isWishlistEmpty;
    const showTrackingItem = CONFIG?.shop?.product?.tracking?.recentlyVisited && trackingCount > 0;
    const shoppingListCount = 0;
    const showShoppingListItem = CONFIG?.shop?.customerProductList?.shoppingList && shoppingListCount > 0;
    return (_jsxs(_Fragment, { children: [isCartEmpty && (_jsxs("div", { className: finalStyles.emptyContainer, children: [_jsx("p", { className: finalStyles.emptyContainer_text, children: t('addProducts') }), showWishlistItem && (_jsxs(DropdownItem, { icon: "toolHeart", href: wishlistPath?.url, children: [t('wishlist'), " (", wishlistCount, ")"] })), showTrackingItem && (_jsx(LinkRoute, { id: "/shop-tracking/Tracking", legacyBehavior: true, children: _jsxs(DropdownItem, { icon: "toolArrowCircle", children: [t('tracking'), " (", trackingCount, ")"] }) })), showShoppingListItem && (_jsxs(DropdownItem, { icon: "toolChecklist", href: "/shopping-list", children: [t('shoppingList'), " (", shoppingListCount, ")"] }))] })), !isCartEmpty && _jsx(HeaderCartList, { data: cartItems })] }));
};
