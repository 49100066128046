'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useBreakpoints } from '@edeeone/juan-core/hooks/useBreakpoints';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getActiveWeb } from '@edeeone/juan-core/routes/utils/getActiveWeb';
import { getHref } from '@edeeone/string/getHref';
import Link from 'next/link';
import { whispererEmptyClassNames } from './WhispererEmpty.styles';
import { useWhispererStore } from './WhispererStoreClient';
import { ResultSectionEnum } from './WhispererTypes';
// TODO: upravit aby se vše bralo z useWhispererStore
export const WhispererEmpty = ({ design = 'primary', styles, }) => {
    const { data, searchQuery: search, loading, totalCountWithHidden: totalCount, linkAll, stageSectionsCountWithoutHidden, } = useWhispererStore();
    const minCharacters = CONFIG.fulltext.summonFilterMin;
    const finalStyles = whispererEmptyClassNames(styles, design);
    const { breakpoints } = useBreakpoints();
    const { t } = useI18n('Whisperer');
    const { webId } = useLocation();
    const activeWeb = getActiveWeb(webId);
    const whispererHiddenSections = activeWeb.fulltext?.whispererHiddenSections || [];
    const countOther = stageSectionsCountWithoutHidden;
    const isAllEmpty = totalCount === 0 &&
        (search?.length || 0) >= minCharacters &&
        !loading &&
        countOther === 0;
    const isEmptyCategory = (search?.length || 0) >= minCharacters &&
        !loading &&
        !data?.edeeFulltextCategories?.totalRecordCount;
    const productsCount = data?.edeeFulltextProduct?.recordPage?.totalRecordCount || 0;
    const isEmptyProduct = (search?.length || 0) >= minCharacters && !loading && !productsCount;
    const whispererSectionsWithoutHidden = data?.edeeFulltextStageMultiSection?.filter((section) => {
        return !whispererHiddenSections.includes(section?.section);
    });
    const countWithoutWhispererHiddenSections = whispererSectionsWithoutHidden?.reduce((acc, it) => {
        return acc + (it?.data?.totalNumberOfRecords || 0);
    }, 0);
    const isEmptyWhispererVisibleSections = (search?.length || 0) >= minCharacters &&
        !loading &&
        !countWithoutWhispererHiddenSections;
    const brandFacetStatisticsLength = data?.edeeFulltextCategories?.totalRecordCount;
    const isEmptyBrands = (search?.length || 0) >= minCharacters &&
        !loading &&
        !brandFacetStatisticsLength;
    const groupsFacetStatisticsLength = data?.edeeFulltextGroups?.totalRecordCount;
    const isEmptyGroups = (search?.length || 0) >= minCharacters &&
        !loading &&
        !groupsFacetStatisticsLength;
    const isAllInWhispererEmpty = (totalCount || 0) > 0 && countOther === totalCount;
    return (_jsxs(_Fragment, { children: [design === 'primary' && isAllEmpty && search != '' && !loading && (_jsx("p", { className: finalStyles.container, children: t('empty', {
                    span: (a) => {
                        return _jsx("span", { children: search }, `emptyAll-${a?.toString()}`);
                    },
                }) })), design === 'primary' &&
                isAllInWhispererEmpty &&
                search != '' &&
                !loading && (_jsxs("p", { className: finalStyles.container, children: [t('emptyWhisperer', {
                        span: (a) => {
                            return (_jsx("span", { children: search }, `emptyWhisperer-${a?.toString()}`));
                        },
                    }), !!whispererSectionsWithoutHidden?.length && (_jsx("span", { className: finalStyles.content, children: t('emptyMoreResults', {
                            totalCount: totalCount,
                            a: (chunks) => {
                                return (_jsx(Link, { href: getHref(linkAll, {
                                        section: whispererSectionsWithoutHidden[0]?.section,
                                    }), className: finalStyles.contentLink, children: chunks }, '0'));
                            },
                        }) }))] })), design === 'secondary' &&
                isEmptyProduct &&
                countOther !== totalCount &&
                search != '' && (_jsxs("p", { className: finalStyles.container, children: [t('emptyProduct', {
                        span: (a) => {
                            return (_jsx("span", { children: search }, `emptyProduct-${a?.toString()}`));
                        },
                    }), !!whispererSectionsWithoutHidden?.length && (_jsx("span", { className: finalStyles.content, children: t('emptyMoreResults', {
                            totalCount: totalCount,
                            a: (chunks) => {
                                return (_jsx(Link, { href: getHref(linkAll, {
                                        section: whispererSectionsWithoutHidden[0]?.section,
                                    }), className: twMergeJuan(finalStyles.contentLink), children: chunks }, '0'));
                            },
                        }) }))] })), design === 'tertiary' &&
                isEmptyCategory &&
                isEmptyWhispererVisibleSections &&
                isEmptyBrands &&
                isEmptyGroups &&
                totalCount !== countOther &&
                search != '' &&
                breakpoints?.includes('sm') && (_jsxs("p", { className: finalStyles.container, children: [t('emptyCategory', {
                        span: (a) => {
                            return (_jsx("span", { children: search }, `emptyCategory-${a?.toString()}`));
                        },
                    }), _jsx("span", { className: finalStyles.content, children: t('emptyMoreProducts', {
                            totalCount: productsCount,
                            a: (chunks) => {
                                return (_jsx(Link, { href: getHref(linkAll, {
                                        section: ResultSectionEnum.products,
                                    }), className: finalStyles.contentLink, children: chunks }));
                            },
                        }) })] }))] }));
};
