import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const dropdownButtonWrapperDefaultClassNames = /* tw */ {
    container: '',
    opener: 'dropdown-wrapper flex items-center justify-between transition-common gap-6 border border-gray-30 hover:border-black focus-within:border-black text-xs text-gray-70 px-10 py-5 radius-common select-none disabled:cursor-not-allowed disabled:opacity-30',
    opener__disabled: 'border-opacity-50 !text-gray/50',
    opener__open: 'border-black',
    opener__openSelected: 'bg-gray-10',
    openerIcon__disabled: 'opacity-50',
    contentModal: 'dropdown-wrapper__content p-12 xsm:px-[6.5rem]',
    contentDropdown: 'dropdown-wrapper__content border border-gray-20 absolute shadow-horizontal-filter mt-4 rounded-3xl p-12 z-30 bg-white min-w-[330px]',
    buttons: 'flex items-center mt-7 gap-12',
    contentAnimation: 'transition-filter-dropdown',
    contentFrom: 'transition-filter-dropdown-from',
    contentTo: 'transition-filter-dropdown-to',
    openerIcon: 'dropdown-wrapper-ico w-[1.8rem] h-[1.8rem] transition-common',
    openerIcon__open: '',
};
export function dropdownButtonWrapperClassNames(...args) {
    return getMemoizedFinalClassnames('dropdownButtonWrapperClassNames', dropdownButtonWrapperDefaultClassNames, ...args);
}
