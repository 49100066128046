import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tagClassNames } from './Tag.styles';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const Tag = ({ children, icon, styles, design = 'primary', size = 'md', }) => {
    const finalStyles = tagClassNames(styles, design, size);
    if (children == null) {
        return null;
    }
    return (_jsxs("span", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [icon && (_jsx(SvgSpriteIcon, { icon: icon, width: "17", height: "17", className: finalStyles.icon, ariaHidden: true })), _jsx("span", { className: finalStyles.content, children: children })] }));
};
