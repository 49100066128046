'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useEffect, useState } from 'react';
import { useDropdownContext } from '@edeeone/juan-core/components/dropdown/Dropdown';
export const HeaderActionsContainer = ({ children, className, finalStyles, id, ...props }) => {
    const [contentHovered, setIsContentHovered] = useState(false);
    const { openId } = useDropdownContext();
    let timer;
    useEffect(() => {
        if (openId === undefined) {
            timer = setTimeout(() => {
                setIsContentHovered(false);
            }, 100);
        }
        else {
            clearTimeout(timer);
            setIsContentHovered(true);
        }
    }, [openId]);
    return (_jsxs("div", { className: className, ...props, id: id, children: [_jsx("span", { className: twMergeJuan(finalStyles.wrapper, openId !== undefined && finalStyles.wrapper__hover) }), _jsx("div", { className: twMergeJuan(finalStyles.overlay, openId !== undefined && finalStyles.overlay__hover) }), _jsx("div", { className: finalStyles.content, children: children })] }));
};
