'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { REGION_SWITCH_PATHNAME } from '@edeeone/juan-core/routes/utils/region';
import { getHref } from '@edeeone/string/getHref';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useFormContext } from '../../hooks/useForm';
import { useLocation } from '../../routes/useLocation';
import { getLocalizationsSetup } from '../../utils/localization';
import { Button } from '../button/Button';
import { Form } from '../form/Form';
import { FormGroup } from '../form/formGroup/FormGroup';
import { RadioGroup } from '../form/radio/RadioGroup';
import { SelectBox } from '../form/selectBox/SelectBox';
import { Title } from '../title/Title';
import { langFormClassNames } from './LangForm.styles';
import { flagPath } from './Language';
const LimitRadio = 1; // 5
// TODO: nahradit z API/configu
const currenciesList = process.env.JUAN_REGIONS;
const regionLanuages = process.env
    .JUAN_REGIONS_LANGUAGES;
const langsList = process.env.JUAN_LANGUAGE;
export const LangForm = function ({ styles, design, onSubmit, showCountryFlags, }) {
    const finalStyles = langFormClassNames(styles, design);
    const { country, currency, locale, pathname } = useLocation();
    const { push } = useRouter();
    const { showCountry, showLang, showCurrency } = getLocalizationsSetup();
    const { t } = useI18n('LangForm');
    return (_jsxs("div", { className: twMergeJuan(finalStyles.root), children: [design !== 'secondary' && (_jsx(Title, { design: "tertiary", as: "h2", styles: {
                    container__custom: finalStyles.title,
                }, children: t('heading', {
                    optionsCount: (showCountry ? 1 : 0) +
                        (showLang ? 1 : 0) +
                        (showCurrency ? 1 : 0),
                    // jazyk, země a měna
                    optionA: showCountry ? t('country') : t('lang'),
                    optionB: showCountry && showLang ? t('lang') : t('currency'),
                    optionC: t('currency'),
                }) })), _jsx(Form, { id: "LangForm", styles: {
                    form: twMergeJuan(finalStyles.form),
                }, defaultValues: {
                    country,
                    currency,
                    lang: locale,
                    target: pathname,
                }, onSubmit: (data) => {
                    const nextRegion = `${data?.country}${data?.lang}${data?.currency}`?.toLowerCase();
                    const computedUrl = getHref(REGION_SWITCH_PATHNAME, {
                        region: nextRegion,
                    });
                    onSubmit && onSubmit(data);
                    push(computedUrl);
                }, children: ({ formState: { isLoading } }) => {
                    return (_jsx(_Fragment, { children: _jsxs("div", { className: finalStyles.formContent, children: [showCountry && (_jsx(LangFormCountry, { heading: t('headingCountry') || 'country', t: t, showCountryFlags: showCountryFlags })), showLang && (_jsx(LangFormLanguage, { heading: t('headingLanguage') || 'language' })), showCurrency && (_jsx(LangFormCurrency, { heading: t('headingCurrency') || 'currency', t: t })), _jsxs("div", { className: finalStyles.btnContainer, children: [_jsx(Button, { type: "submit", disabled: Boolean(isLoading), loading: isLoading, styles: { button__custom: finalStyles.btnPrimary }, children: t('submit') }), _jsx(Button, { design: "quaternary", type: "button", onClick: () => {
                                                onSubmit && onSubmit({ country, currency, lang: locale });
                                            }, children: t('cancel') })] })] }) }));
                } })] }));
};
export default LangForm;
export const LangFormCountry = ({ heading, t, showCountryFlags, }) => {
    const finalStyles = langFormClassNames();
    const countryList = useMemo(() => {
        let country = [];
        currenciesList?.forEach((c) => {
            country.push({
                // label: t(`country.${c?.countryCode}`),
                label: (_jsxs("span", { className: finalStyles.formGroup__radioLabel, children: [showCountryFlags && (_jsx(Image, { src: `${flagPath}${c.countryCode?.toLowerCase()}.svg`, alt: 'flag', width: 15, height: 15, unoptimized: true, className: finalStyles.icon })), t(`country.${c?.countryCode}`)] })),
                value: c?.countryCode,
            });
        });
        return country;
    }, [t]);
    return (_jsx(FormGroup, { name: "country", label: heading, showOptionalLabelPostfix: false, styles: {
            group__custom: countryList?.length <= LimitRadio ? finalStyles.formGroup__radio : '',
            label__custom: countryList?.length <= LimitRadio
                ? finalStyles.formGroupLabel__radio
                : '',
        }, children: (countryList?.length > LimitRadio && (_jsx(SelectBox, { name: 'country', items: countryList, showDropdownOnSmallDevice: true }))) || (_jsx(RadioGroup, { design: "secondary", id: "country", name: "country", items: countryList })) }));
};
export const LangFormLanguage = ({ heading }) => {
    const finalStyles = langFormClassNames();
    const { watch, getValues, setValue, formState: { defaultValues }, } = useFormContext();
    const { country } = getValues();
    watch('country');
    const langList = useMemo(() => {
        let langs = [];
        currenciesList
            ?.find((c) => {
            return c?.countryCode === country;
        })
            ?.languages?.forEach((l) => {
            if (langs?.find((c) => {
                return c?.value === l?.lang;
            }) === undefined) {
                langs.push({
                    label: langsList?.[l?.lang] || l?.lang,
                    value: l?.lang,
                });
            }
        });
        const currentLang = getValues()?.lang;
        const existLang = langs?.find((l) => {
            return l?.value === currentLang;
        });
        if (existLang === undefined) {
            setValue('lang', String(langs?.[0]?.value));
        }
        return langs;
    }, [country]);
    return (_jsx(_Fragment, { children: _jsx(FormGroup, { name: "lang", label: heading, showOptionalLabelPostfix: false, styles: {
                group__custom: langList?.length <= LimitRadio ? finalStyles.formGroup__radio : '',
                label__custom: langList?.length <= LimitRadio
                    ? finalStyles.formGroupLabel__radio
                    : '',
            }, children: (langList?.length > LimitRadio && (_jsx(SelectBox, { showDropdownOnSmallDevice: true, name: 'lang', items: langList }))) || (_jsx(RadioGroup, { id: "lang", name: "lang", design: "secondary", initialValue: defaultValues?.lang, items: langList })) }) }));
};
export const LangFormCurrency = ({ heading, t }) => {
    const finalStyles = langFormClassNames();
    const { getValues, setValue, watch, formState: { defaultValues }, } = useFormContext();
    const { lang, country } = getValues();
    watch('lang', 'country');
    const currencies = useMemo(() => {
        const c = process.env.JUAN_REGIONS_LANGUAGES;
        let currencyList = [];
        currenciesList?.forEach((c) => {
            if (country === c?.countryCode || country === undefined) {
                c?.languages?.forEach((l) => {
                    if (l?.lang === lang || lang === undefined) {
                        currencyList.push({
                            label: `${l?.currency} (${t(`currency.${l?.currency.toLowerCase()}.symbol`)})`,
                            value: l?.currency,
                        });
                    }
                });
            }
        });
        const currentCurrency = getValues()?.currency;
        const existCurrency = currencyList?.find((l) => {
            return l?.value === currentCurrency;
        });
        if (existCurrency === undefined) {
            setValue('currency', String(currencyList?.[0]?.value));
        }
        return currencyList;
    }, [lang, country, t]);
    return (_jsx(FormGroup, { name: "currency", label: heading, showOptionalLabelPostfix: false, styles: {
            group__custom: currencies?.length <= LimitRadio ? finalStyles.formGroup__radio : '',
            label__custom: currencies?.length <= LimitRadio
                ? finalStyles.formGroupLabel__radio
                : '',
        }, children: (currencies?.length > LimitRadio && (_jsx(SelectBox, { showDropdownOnSmallDevice: true, name: 'currency', items: currencies }))) || (_jsx(RadioGroup, { id: "currency", name: "currency", design: "secondary", items: currencies })) }));
};
