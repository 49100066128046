import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const languageDefaultClassNames = /* tw */ {
    container: 'lang-switcher flex items-center font-normal text-xs tracking-normal empty:hidden print:hidden',
    item: 'lang-switcher__item border-r pr-4 mr-4 last-of-type:border-none last-of-type:pr-0 last-of-type:mr-0',
    item__dropdown: 'border-none pr-0',
    icon: 'lang-switcher__ico ico w-6 h-6',
    iconDropdown: 'lang-switcher__ico-dropdown w-4 h-4 ml-4',
    // langSwitcher ve footeru
    primary: {
        container: '',
        item: 'border-gray-30',
        icon: 'fill-gray-60',
        iconDropdown: 'fill-gray-60',
    },
    // langSwitcher v záhlaví
    secondary: {
        container: 'text-white',
        item: 'border-gray-30',
        icon: 'fill-white',
        iconDropdown: 'fill-white',
    },
    // langSwitcher v RWD menu
    tertiary: {
        container: 'text-s text-gray-70 py-6',
        item: 'border-gray-30',
        icon: 'fill-gray-60',
        iconDropdown: 'fill-gray-60',
    },
};
export function languageClassNames(...args) {
    return getMemoizedFinalClassnames('languageClassNames', languageDefaultClassNames, ...args);
}
