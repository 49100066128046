import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { DropdownItemProduct } from '@edeeone/juan-core/components/dropdown/DropdownItemProduct';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useProductComparison } from '@edeeone/shop-productcomparison/hooks/useProductComparison';
import { headerComparisonItemClassNames } from './HeaderComparisonItem.styles';
const config = CONFIG?.componentSettings?.shopProductComparison?.HeaderProductComparisonItem;
export const HeaderComparisonItem = ({ productId, styles = config?.styles, buttonDesign = config?.buttonDesign ?? 'tertiary', buttonIcon = config?.buttonIcon ?? 'cross', buttonIconSize = config?.buttonIconSize ?? '10', ...props }) => {
    const finalStyles = headerComparisonItemClassNames(styles);
    const { t } = useI18n('HeaderComparison');
    const { removeProductFromProductComparison } = useProductComparison();
    const handleRemoveItemProductComparison = async (e) => {
        e.preventDefault();
        await removeProductFromProductComparison(productId);
    };
    return (_jsx(DropdownItemProduct, { ...props, children: _jsx("form", { onSubmit: handleRemoveItemProductComparison, children: _jsx(Button, { type: "submit", design: buttonDesign, icon: buttonIcon, iconSize: buttonIconSize, styles: { button__custom: finalStyles.button }, title: t('remove') }) }) }));
};
