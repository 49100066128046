'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { megamenuItemClassNames } from '@edeeone/juan-core/components/megamenu/MegamenuItem.styles';
import { MegamenuLink } from '@edeeone/juan-core/components/megamenu/MegamenuLink';
import { useMegamenuContext } from '@edeeone/juan-core/components/megamenu/useMegamenu';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useBreakpoints } from '@edeeone/juan-core/hooks/useBreakpoints';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { autoUpdate, offset, safePolygon, shift, useClick, useFloating, useHover, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
export const MegamenuItem = ({ level = 0, data, styles = CONFIG?.componentSettings?.juanCore?.MegamenuItem?.styles, design = CONFIG?.componentSettings?.juanCore?.MegamenuItem?.design ??
    'primary', hideMotive = CONFIG?.componentSettings?.juanCore?.MegamenuItem?.hideMotive ??
    false, sendToParent, displayShowAllLink = CONFIG?.componentSettings?.juanCore?.MegamenuItem
    ?.displayShowAllLink ?? true, }) => {
    const finalStyles = megamenuItemClassNames(styles, design);
    const { t } = useI18n('Megamenu');
    const { open, maxDepth, setOpen, close } = useMegamenuContext();
    const isOpen = open?.[level] === data?.primaryKey;
    const haveChildren = (data?.children?.length || 0) > 0;
    const handleToggle = (nextState) => {
        if (!nextState && !haveChildren) {
            return;
        }
        if (nextState !== isOpen &&
            open.length >= level &&
            data?.onClick === undefined) {
            let nextOpen = open.filter((_o, i) => {
                return i < level;
            });
            if (nextState) {
                nextOpen[level] = data.primaryKey;
            }
            setOpen(nextOpen);
        }
    };
    const { refs, context } = useFloating({
        open: isOpen,
        placement: 'bottom',
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(10),
            shift({
                padding: 20,
            }),
        ],
    });
    const { breakpoints } = useBreakpoints();
    const isDesktopView = breakpoints?.includes('xl');
    const hover = useHover(context, {
        delay: {
            close: 100,
            open: 100,
        },
        enabled: isDesktopView && level === 0,
        handleClose: safePolygon({
            buffer: 42,
        }),
    });
    const click = useClick(context);
    const role = useRole(context, { role: 'menu' });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        click,
        role,
    ]);
    const { status } = useTransitionStatus(context);
    const handleMouseEnter = () => {
        if (!isDesktopView) {
            return;
        }
        sendToParent?.(haveChildren);
        handleToggle(true);
    };
    const handleMouseLeave = () => {
        if (!isDesktopView) {
            return;
        }
        sendToParent?.(false);
        handleToggle(false);
        //NOTE: Handle when user hover on item without children to remove active state
        if (!data.children?.length && open.length === 1) {
            setOpen([]);
        }
    };
    const handleClose = () => {
        close();
        handleToggle(false);
    };
    return (_jsxs("div", { ref: refs.setReference, ...getReferenceProps(), className: twMergeJuan(finalStyles.li, haveChildren && finalStyles.li__haveChildren, finalStyles?.[`li__level${level}`], `level-${level}`), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsx(MegamenuLink, { data: data, level: level, handleToggle: (next) => {
                    return handleToggle(next);
                }, isOpen: isOpen, design: design, hideMotive: hideMotive }), _jsx(Transition, { appear: true, show: isOpen, as: Fragment, children: _jsx(Transition.Child, { as: Fragment, enter: finalStyles.containerAnimation, enterFrom: finalStyles.containerFrom, enterTo: finalStyles.containerTo, leave: finalStyles.containerAnimation, leaveFrom: finalStyles.containerTo, leaveTo: finalStyles.containerFrom, children: _jsxs("div", { className: twMergeJuan(finalStyles.panel, level == 0 && finalStyles.panel__level0, level == 1 && finalStyles.panel__level1, !(isOpen && haveChildren) && finalStyles.panel__closed), "data-status": status, ref: refs.setFloating, ...getFloatingProps(), children: [_jsxs("div", { className: finalStyles.header, children: [_jsxs("button", { className: finalStyles.backButton, onClick: () => {
                                            handleToggle(false);
                                        }, children: [_jsx(SvgSpriteIcon, { className: finalStyles.backButton_icon, icon: "arrow", ariaHidden: true }), data?.name] }), _jsx("button", { className: finalStyles.closeButton, "aria-label": t('Megamenu.close'), onClick: handleClose, children: _jsx(SvgSpriteIcon, { icon: "cross", className: finalStyles.closeButtonIcon }) })] }), _jsxs("nav", { className: finalStyles.list, children: [data?.url && displayShowAllLink && (_jsxs(_Fragment, { children: [_jsx(Link, { href: data?.url, className: finalStyles.showAll, children: t('Megamenu.showAll') }), _jsx("div", { className: finalStyles.showAllButton, onClick: () => {
                                                    close();
                                                }, children: _jsx(Button, { href: data?.url, design: "secondary", children: t('Megamenu.showAllFrom', {
                                                        category: data?.name,
                                                    }) }) })] })), data?.children?.map((child) => {
                                        if (level < maxDepth) {
                                            return (_jsx(Fragment, { children: _jsx(MegamenuItem, { design: "tertiary", data: child, level: level + 1, hideMotive: hideMotive }) }, `megamenu-overlay-${level}-${child?.primaryKey}`));
                                        }
                                    })] })] }) }) })] }));
};
