export const searchHistoryKey = 'searchHistory';
export var ResultSectionEnum;
(function (ResultSectionEnum) {
    ResultSectionEnum["products"] = "products";
    ResultSectionEnum["brands"] = "brands";
    ResultSectionEnum["groups"] = "groups";
})(ResultSectionEnum || (ResultSectionEnum = {}));
export const whispererDefaultValues = {
    search: '',
    loading: false,
    submitted: 0,
};
export const getSearchHistory = () => {
    // TODO asi by se mělo i liminotovat při přidávání do storage
    const { searchHistory: limit } = CONFIG.fulltext.whispererConfig.maxAmountShown;
    let items = [];
    try {
        items = JSON.parse(window?.localStorage?.getItem(searchHistoryKey) || '[]');
    }
    catch {
        // silencing error
    }
    return items.slice(0, limit);
};
