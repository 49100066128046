'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { MegamenuItem } from '@edeeone/juan-core/components/megamenu/MegamenuItem';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useWishlist } from '@edeeone/shop-wishlist/hooks/useWishlist';
export const MenuWishlist = ({ itemDesign = CONFIG?.componentSettings?.shopWishlist?.MenuWishlist
    ?.itemDesign ?? 'secondary', itemIcon = CONFIG?.componentSettings?.shopWishlist?.MenuWishlist?.itemIcon ??
    'toolHeart', }) => {
    const { t } = useI18n('MenuWishlist');
    const { isWishlistEmpty, wishlistCount } = useWishlist();
    const route = useLinkRoute({
        id: '/shop-wishlist/Wishlist',
    });
    return (_jsx(MegamenuItem, { design: itemDesign, data: {
            primaryKey: 'shop-wishList',
            name: t('label') || 'wishlist',
            url: route?.url,
            icon: itemIcon,
            iconBadge: !isWishlistEmpty && wishlistCount,
        } }));
};
