import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useCreditsQuery } from '../graphql/credit.codegen';
export const useUserProfileCredit = () => {
    const { region, currency } = useLocation();
    const { data } = useCreditsQuery({
        variables: {
            args: {
                catalogRegion: region,
            },
        },
        context: {
            tags: ['userProfile'],
        },
        skip: CONFIG.shop.credit === 'undefined',
    });
    return data?.credits?.amount ? data?.credits : { amount: 0, currency };
};
