import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const headerWishlistDefaultClassNames = /* tw */ {
    container: 'header-wishlist max-h-[175px] overflow-y-auto custom-scrollbar px-6 flex',
    items: 'header-wishlist__items header-products flex flex-col gap-6 items-center w-full',
    button: 'relative block',
    buttonIcon: 'relative block',
    label: 'label label--header',
    icon: 'ico ico--header',
};
export function headerWishlistClassNames(...args) {
    return getMemoizedFinalClassnames('headerWishlistClassNames', headerWishlistDefaultClassNames, ...args);
}
