'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import LangForm from '@edeeone/juan-core/components/language/LangForm';
import { Modal } from '@edeeone/juan-core/components/modal/Modal';
import { cloneElement, isValidElement, useState } from 'react';
export const LangModal = ({ button, buttonAlt, showCountryFlags, buttonDesign = 'quinary', }) => {
    if (button === undefined && buttonAlt === undefined) {
        throw new Error('Button or ButtonAlt must be defined');
    }
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [button && (_jsx(Button, { design: buttonDesign, onClick: () => {
                    return setIsOpen(!isOpen);
                }, children: button })), isValidElement(buttonAlt) &&
                // cloneElement(buttonAlt, getReferenceProps({ ref, ...children.props }))}
                cloneElement(buttonAlt, {
                    ...(buttonAlt?.props || {}),
                    onClick: () => {
                        return setIsOpen(!isOpen);
                    },
                }), _jsx(Modal, { isOpen: isOpen, setIsOpen: (nextState) => {
                    return setIsOpen(nextState);
                }, children: _jsx(LangForm, { onSubmit: () => {
                        return setIsOpen(false);
                    }, showCountryFlags: showCountryFlags }) })] }));
};
