'use client';
import { isTouchDevice } from '@edeeone/juan-core/utils/isTouchDevice';
export function getDeviceType() {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isTouchDeviceFinal = isTouchDevice();
    // Check for iOS. iOS in new versions no longer includes "iPad" in userAgent
    if (/iPad|iPhone|iPod/.test(userAgent) ||
        (navigator.userAgent.includes('Mac') && isTouchDeviceFinal)) {
        return 'mobile'; // or "tablet" according to other parameters, if relevant
    }
    // Android or other mobile devices
    if (/Android/.test(userAgent)) {
        return 'mobile';
    }
    // Estimate based on screen size
    if (isTouchDeviceFinal && window.innerWidth >= 768) {
        return 'tablet';
    }
    return 'desktop';
}
