import { jsx as _jsx } from "react/jsx-runtime";
import { useStorageImage } from '@edeeone/juan-core/components/image/useStorageImage';
import { removeTags } from '@edeeone/string/removeTags';
import { Image } from './Image';
/**
 *
 * @deprecated
 * instead use `Image` component and write complexer graphql queries with imageFields fragment
 */
export const StorageImage = function ({ motiveId, placeholder = process.env.IMAGE_PLACEHOLDER, width, height, design = 'primary', alt, aspectRatio, renderPlaceholder = true, priority, loading = !priority ? 'lazy' : undefined, key, ...props }) {
    // TODO - relatedFiles can return files.{ url, description, ...etc } from GQL, maybe take it from there
    let { url, description, modified, name } = useStorageImage(Number(motiveId)) || {};
    if (!motiveId && !url && !renderPlaceholder) {
        return null;
    }
    if (!url) {
        url = placeholder;
    }
    return (_jsx(Image, { src: url, alt: removeTags(description) || alt || '', aspectRatio: aspectRatio, loading: loading, width: width, height: height, modified: modified, priority: priority, ...props }, key));
};
