'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '@edeeone/juan-core/Link';
import { useConsent } from '@edeeone/juan-core/hooks/useConsent';
import { useFormContext } from '@edeeone/juan-core/hooks/useForm';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { getHref } from '@edeeone/string/getHref';
import { useEffect, useMemo } from 'react';
import { Button } from '../button/Button';
import { Form } from '../form/Form';
import { CookiePreferenceSection } from './CookiePreferenceSection';
import { cookiePreferencesFormClassNames } from './cookiePreferencesForm.styles';
const order = ['TECHNICAL', 'ANALYTICS', 'MARKETING'];
export const CookiePreferencesForm = ({ styles, onSubmit: onSubmitExternal, options = CONFIG.consent || ['TECHNICAL', 'ANALYTICS', 'MARKETING'], design = 'primary', urlToPrivacyPolicy = getHref('/privacy-policy'), showButtons = true, }) => {
    const finalStyles = cookiePreferencesFormClassNames(styles, design);
    const { t } = useI18n('CookiePreferencesForm');
    const sortedOptions = useMemo(() => {
        return (options?.sort((a) => {
            return order?.indexOf(a);
        }) || []);
    }, [options]);
    const { updateConsent, isGranted, consent } = useConsent();
    const onSubmit = (data) => {
        const accepted = Object?.keys(data)
            ?.filter((k) => {
            return data[k];
        })
            ?.map((key) => {
            if (data[key]) {
                return key;
            }
        });
        if (accepted !== consent) {
            updateConsent(accepted, true, true);
        }
        if (onSubmitExternal) {
            onSubmitExternal(data);
        }
    };
    return (_jsxs(Form, { id: "CookiePreferencesForm", styles: {
            form: finalStyles.form,
        }, defaultValues: {
            TECHNICAL: true,
            ANALYTICS: isGranted('ANALYTICS'),
            MARKETING: isGranted('MARKETING'),
        }, onSubmit: onSubmit, children: [showButtons && (_jsx("p", { className: finalStyles.description, children: t('description', {
                    a: (chunks) => {
                        return (_jsx(Link, { href: urlToPrivacyPolicy, className: finalStyles.description_a, onClick: () => {
                                // only close modal if it is open
                                if (onSubmitExternal) {
                                    onSubmitExternal({});
                                }
                            }, children: chunks }));
                    },
                }) })), showButtons && (_jsx(Button, { type: "button", design: "primary", onClick: () => {
                    updateConsent(options?.map((option) => {
                        return option;
                    }), true, true);
                    if (onSubmitExternal) {
                        onSubmitExternal({});
                    }
                }, children: t('all') })), !showButtons && (_jsx(CookiePreferencesFormObserver, { submitFunction: onSubmit })), _jsx("div", { className: finalStyles.container, children: sortedOptions?.map((option) => {
                    return (_jsx(CookiePreferenceSection, { design: design, type: option, forced: option === 'TECHNICAL' }, `cookie-preference-section-${option}`));
                }) }), showButtons && (_jsx(Button, { type: "submit", design: "primary", children: t('submit') }))] }));
};
export const CookiePreferencesFormObserver = ({ submitFunction }) => {
    const { watch, getValues } = useFormContext();
    watch(['ANALYTICS', 'MARKETING', 'TECHNICAL']);
    const { ANALYTICS, MARKETING, TECHNICAL } = getValues();
    useEffect(() => {
        submitFunction({ ANALYTICS, MARKETING, TECHNICAL });
    }, [ANALYTICS, MARKETING, TECHNICAL]);
    return null;
};
