'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '@edeeone/juan-core/components/skeleton/Skeleton';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { arrow, autoUpdate, flip, FloatingArrow, FloatingOverlay, FloatingPortal, offset, safePolygon, shift, useClick, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { useRouter } from 'next/navigation';
import { createContext, Suspense, useContext, useEffect, useId, useRef, useState, } from 'react';
import { dropdownClassNames } from './dropdown.styles';
export const Dropdown = ({ button, buttonLabel, heading, design, size, children, styles, placement = 'bottom', showArrow = true, showOverlay = true, footer, showClose, openOnHover = false, hideHeaderBorder = false, onButtonClick, onTouchDropdown = true, isOpen: isOpenOverride, setIsOpen: setIsOpenOverride, href, id: idProp, }) => {
    const { t } = useI18n('Dropdown');
    const finalStyles = dropdownClassNames(styles, design, size);
    const { push } = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    const { setOpenId, openId } = useDropdownContext();
    const generatedId = useId();
    const id = idProp || generatedId;
    useEffect(() => {
        if (openId !== id && isOpen) {
            setIsOpen(false);
        }
    }, [openId]);
    function handleToggle(nextState) {
        if (setIsOpenOverride) {
            setIsOpenOverride(nextState);
        }
        else if (nextState !== isOpen) {
            setOpenId(nextState ? id : undefined);
            setIsOpen(nextState);
        }
    }
    const arrowRef = useRef(null);
    useChangePathname({
        callback: () => {
            return setIsOpen(false);
        },
    });
    const { x, y, refs, strategy, context } = useFloating({
        open: typeof isOpenOverride === 'boolean' ? isOpenOverride : isOpen,
        onOpenChange: handleToggle,
        placement,
        // Make sure the tooltip stays on the screen
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(10),
            flip(),
            shift({
                padding: 20,
            }),
            arrow({ element: arrowRef, padding: 10 }),
        ],
    });
    const hover = useHover(context, {
        delay: {
            close: 300,
            open: 0,
        },
        enabled: openOnHover,
        mouseOnly: true,
        handleClose: safePolygon({
            buffer: 1,
            requireIntent: false,
            blockPointerEvents: true,
        }),
    });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const click = useClick(context);
    const role = useRole(context, { role: 'menu' });
    const refWasTouched = useRef(false);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        click,
        focus,
        dismiss,
        role,
    ]);
    const { status, isMounted } = useTransitionStatus(context);
    const content = (_jsxs("div", { className: finalStyles.popover, ref: refs.setFloating, "data-status": status, style: {
            // Positioning styles
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
        }, ...getFloatingProps(), children: [_jsx("div", { className: finalStyles.popoverHeader }), _jsxs("div", { className: twMergeJuan(finalStyles.popoverContent, finalStyles.popoverContent__custom), children: [showArrow && (_jsx(FloatingArrow, { ref: arrowRef, context: context, fill: finalStyles.popoverArrowFill, stroke: finalStyles.popoverArrowStroke, strokeWidth: parseInt(finalStyles.popoverArrowStrokeWidth || '0') })), heading && (_jsxs("div", { className: twMergeJuan(finalStyles.header, hideHeaderBorder && finalStyles.header__hideBorder, showClose && finalStyles.header__close), children: [heading, showClose && (_jsx("button", { className: finalStyles.closeButton, "aria-label": t('close'), onClick: () => {
                                    handleToggle(false);
                                }, children: _jsx(SvgSpriteIcon, { className: finalStyles.closeButtonIcon, icon: "cross" }) }))] })), _jsxs(Suspense, { fallback: _jsx("div", { className: finalStyles.content, children: _jsx(Skeleton, { count: 3 }) }), children: [_jsx("div", { className: finalStyles.content, children: children }), footer && _jsx("div", { className: finalStyles.footer, children: footer })] })] })] }));
    function onClickOverrideProp() {
        if (onButtonClick) {
            return {
                onClick: (e) => {
                    if (!refWasTouched.current || onTouchDropdown) {
                        setIsOpen(false);
                        onButtonClick(e);
                    }
                    // refWasTouched.current = false;
                },
            };
        }
        if (href) {
            return {
                onClick: (e) => {
                    if (!refWasTouched.current || onTouchDropdown) {
                        setIsOpen(false);
                        push(href);
                    }
                },
            };
        }
    }
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsx("button", { ref: refs.setReference, className: twMergeJuan(finalStyles.button, finalStyles.button__custom), onTouchEnd: (e) => {
                    refWasTouched.current = true;
                }, "aria-label": buttonLabel, ...getReferenceProps(), ...onClickOverrideProp(), children: button }), isMounted && !showOverlay && _jsx(FloatingPortal, { children: content }), isMounted && showOverlay && (_jsx(FloatingOverlay, { className: finalStyles.overlay, children: content }))] }));
};
export const DropdownGroup = ({ children }) => {
    const [dropdownId, setDropdownId] = useState();
    return (_jsx(DropdownContext.Provider, { value: { openId: dropdownId, setOpenId: setDropdownId }, children: children }));
};
export const DropdownContext = createContext({
    openId: undefined,
    setOpenId: () => { },
});
export const useDropdownContext = function () {
    return useContext(DropdownContext);
};
