'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { MegamenuItem } from '@edeeone/juan-core/components/megamenu/MegamenuItem';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useProductComparison } from '@edeeone/shop-productcomparison/hooks/useProductComparison';
export const MenuComparison = ({ itemDesign = CONFIG?.componentSettings?.shopProductComparison
    ?.MenuProductComparison?.itemDesign ?? 'secondary', itemIcon = CONFIG?.componentSettings?.shopProductComparison
    ?.MenuProductComparison?.itemIcon ?? 'toolChart', }) => {
    const { t } = useI18n('MenuComparison');
    const { isProductComparisonEmpty, productComparisonCount } = useProductComparison();
    const comparisonPathData = useLinkRoute({
        id: '/shop-productComparison/ProductComparison',
    });
    const comparisonPath = comparisonPathData?.url;
    return (_jsx(MegamenuItem, { design: itemDesign, data: {
            primaryKey: 'product-comparison',
            name: t('label') || 'compare',
            url: comparisonPath || '',
            icon: itemIcon,
            iconBadge: !isProductComparisonEmpty && productComparisonCount,
        } }));
};
