import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const availabilityDefaultClassNames = /* tw */ {
    root: 'availability text-xs font-medium',
    root__custom: '',
    md: {
        root: 'text-sm',
    },
};
export function availabilityClassNames(...args) {
    return getMemoizedFinalClassnames('availabilityClassNames', availabilityDefaultClassNames, ...args);
}
