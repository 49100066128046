'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLogout } from '@edeeone/edee-registration/components/useLogout';
import { useMe } from '@edeeone/edee-registration/useMe';
import { Badge } from '@edeeone/juan-core/components/badge/Badge';
import { Dropdown } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { DropdownItem } from '@edeeone/juan-core/components/dropdown/DropdownItem';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useCompanyDetailOfCurrentUserQuery } from '@edeeone/shop-company/graphql/requests/companyDetailOfCurrentUser.codegen';
import { updateUserDataInDataLayer } from '@edeeone/shop-facebookbusiness/googleTagManager/updateUserDataInDataLayer';
import { getHref } from '@edeeone/string/getHref';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { HeaderLogin } from '../headerLogin/HeaderLogin';
import { HeaderUser } from '../headerUser/HeaderUser';
import { UserModal } from '../UserModal';
import { headerRegistrationClassNames } from './HeaderRegistration.styles';
// let refreshTimer: NodeJS.Timeout | null;
export const HeaderRegistration = ({ styles = CONFIG?.componentSettings?.shopUser?.HeaderRegistration?.styles, showOverlay = CONFIG?.componentSettings?.shopUser?.HeaderRegistration
    ?.showOverlay ?? false, showLabel = CONFIG?.componentSettings?.shopUser?.HeaderRegistration
    ?.showLabel ?? false, }) => {
    const user = useMe();
    const { push: navigateTo, refresh } = useRouter();
    // TODO to je workaround. Přepracovat. Děje se že se zacyklí. NEvolat při renderu. A přesunout jinam.
    /*
    const { user_in: userLoggedIn } = getCookiesFromDocument();
  
    if (
      // client cookies differ from server cookies -> need to refresh the server components
      ((!user?.user?.login && userLoggedIn) ||
        (user?.user?.login && !userLoggedIn)) &&
      // set in debouncing timer to avoid multi-refreshing
      !refreshTimer
    ) {
      refreshTimer = setTimeout(() => {
        refresh();
        refreshTimer = null;
      }, 100);
    }
  */
    const finalStyles = headerRegistrationClassNames(styles);
    const { t } = useI18n('HeaderRegistration');
    const { data } = useCompanyDetailOfCurrentUserQuery();
    const { logout, loading } = useLogout();
    const { url, locale } = useLocation();
    const profilePathData = useLinkRoute({
        id: '/shop-user/profile',
    });
    const profilePath = profilePathData?.url;
    const { routeId } = useLocation();
    const cartPathData = useLinkRoute({
        id: '/shop-orders/cart',
    });
    const cartPath = cartPathData?.url;
    // TODO přesměrování přesunout do logout mutace, popř v server action, popž v hooku
    const redirectToCartAfterLogout = [
        '/shop-orders/shipping-and-payment',
        '/shop-orders/contact',
        '/shop-orders/summary',
        '/shop-orders/paygate-redirect',
        '/shop-orders/confirmation',
    ].includes(routeId);
    const heading = (user?.lastName &&
        `${user?.firstName ? `${user?.firstName} ` : ''}${user?.lastName}`) ||
        user?.email;
    const headingCompany = data?.companyDetailOfCurrentUser?.name;
    // TODO přesunout do GTM hooku
    useEffect(() => {
        if (user?.login) {
            const userForGtm = {
                countryCode: user?.countryCode,
                email: user?.email?.toLowerCase(),
                firstName: user?.firstName?.toLowerCase(),
                lastName: user?.lastName?.toLowerCase(),
                phone: user?.phone?.replace(/ /g, ''),
                postCode: user?.postCode,
            };
            updateUserDataInDataLayer(locale, userForGtm);
        }
    }, [user]);
    return (_jsxs(_Fragment, { children: [user?.isLogged && (_jsx(Dropdown, { openOnHover: true, showOverlay: showOverlay, button: _jsxs("span", { className: finalStyles.button, children: [_jsxs("span", { className: finalStyles.buttonIcon, children: [_jsx(SvgSpriteIcon, { icon: "headerUser", className: finalStyles.icon }), _jsx(Badge, { size: "sm", display: "positioned", border: true, icon: "check" })] }), showLabel && (_jsx("span", { className: finalStyles.label, children: t('myAccount') }))] }), buttonLabel: t('myAccount'), heading: _jsxs("div", { className: finalStyles.heading, children: [_jsx("span", { className: finalStyles.headingUser, children: heading }), headingCompany && (_jsx("span", { className: finalStyles.headingCompany, children: headingCompany }))] }), footer: _jsx(DropdownItem, { icon: "toolShutdown", onClick: () => {
                        return logout().then(async () => {
                            if (redirectToCartAfterLogout) {
                                navigateTo(cartPath);
                            }
                            else {
                                await refreshTags(['userProfile']);
                            }
                        });
                    }, loading: loading, children: _jsx("span", { className: finalStyles.item, children: t('logout') }) }), href: profilePath, children: _jsx(HeaderUser, {}) })), !user?.isLogged && (_jsx(Dropdown, { openOnHover: true, showOverlay: showOverlay, button: _jsxs("span", { className: finalStyles.button, children: [_jsx("span", { className: finalStyles.buttonIcon, children: _jsx(SvgSpriteIcon, { icon: "headerUser", className: finalStyles.icon }) }), showLabel && (_jsx("span", { className: finalStyles.label, children: t('login') }))] }), buttonLabel: t('login'), heading: t('heading'), onButtonClick: () => {
                    window.history.pushState({}, '', getHref(url, { user: 1 }));
                }, children: _jsx(HeaderLogin, {}) })), _jsx(UserModal, {})] }));
};
export default HeaderRegistration;
