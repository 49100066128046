'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { getHref } from '@edeeone/string/getHref';
import Link from 'next/link';
import { WhispererResult } from '../WhispererResult';
import { useWhispererStore } from '../WhispererStoreClient';
import { ResultSectionEnum } from '../WhispererTypes';
import { listingCategoriesClassNames } from './ListingCategories.styles';
import { WhispererHeading } from './WhispererHeading';
import { WhispererItem } from './WhispererItem';
export const ListingCategories = ({ showMoreLink, }) => {
    const finalStyles = listingCategoriesClassNames();
    const { t } = useI18n('Whisperer');
    const { data, categoriesCount: totalCount, linkAll } = useWhispererStore();
    const limit = data?.edeeFulltextCategories?.data?.length ?? 0;
    const showMore = showMoreLink && totalCount > limit;
    if (!totalCount) {
        return null;
    }
    return (_jsxs(WhispererResult, { design: "secondary", styles: { container__custom: finalStyles.container }, children: [_jsx("div", { className: finalStyles.headingContainer, children: _jsx(WhispererHeading, { heading: t('category'), count: data?.edeeFulltextCategories?.totalRecordCount || undefined, href: getHref(linkAll, {
                        section: ResultSectionEnum.products,
                    }) }) }), data?.edeeFulltextCategories?.data?.map(({ facetEntity: single }, i) => {
                return (_jsx("div", { children: _jsx(WhispererItem, { href: single?.attributes?.url, heading: single?.attributes?.name, category: single?.parents?.[0]?.attributes?.name }) }, `category-${single?.attributes?.url || i}`));
            }), showMore && (_jsx(Link, { href: getHref(linkAll, {
                    section: ResultSectionEnum.products,
                }), className: twMergeJuan(finalStyles.moreLink), tabIndex: -1, children: t('moreResults', { totalCount: totalCount - limit }) }))] }));
};
