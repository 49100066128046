import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const dropdownItemDefaultClassNames = /* tw */ {
    container: 'dropdown__item flex gap-6 text-left group/item items-center hover:text-black',
    icon: 'dropdown__ico ico w-8 h-8 shrink-0 !transition-none fill-gray-30 group-hover/item:fill-black',
    loader: 'dropdown__loader loader w-8 h-8 text-gray-30',
    content: 'dropdown__text',
    // polozka s ikonou
    primary: {
        container: 'text-gray-70',
        content: 'text-sm',
    },
    // polozka bez ikony (Vsechny moznosti)
    secondary: {
        container: 'text-gray-60',
        content: 'text-xs underline hover:no-underline',
    },
};
export function dropdownItemClassNames(...args) {
    return getMemoizedFinalClassnames('dropdownItemClassNames', dropdownItemDefaultClassNames, ...args);
}
