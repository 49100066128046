import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const productVariantDefaultClassNames = /* tw */ {
    container: 'product-tile__variants text-gray-60 text-xs',
    container__custom: '',
    item: 'product-tile__variants-item',
    separator: 'product-tile__variants-separator',
    parameter: 'product-tile__variants-parameter text-gray-60 text-xs',
    parameterValue: 'product-tile__variants-parameter-value text-gray-70 text-xs font-semibold',
    // výpis variant v produktové dlaždici
    primary: {
        container: 'mt-8 mb-8',
    },
    // řádkový výpis variant v seznamu oblíbených
    secondary: {
        container: 'text-left line-clamp-1',
        item: 'after:content-[","] last:after:content-none inline-block mr-2 last:mr-0',
    },
};
export function productVariantClassNames(...args) {
    return getMemoizedFinalClassnames('productVariantClassNames', productVariantDefaultClassNames, ...args);
}
