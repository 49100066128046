'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
export const MegamenuExternalControl = ({ children, }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (_jsx(MegamenuExternalControlContext.Provider, { value: {
            open: isOpen,
            setOpen: setIsOpen,
        }, children: children }));
};
export const MegamenuExternalControlContext = createContext({
    open: undefined,
    setOpen: () => { },
});
export const useMegamenuExternalControl = function () {
    return useContext(MegamenuExternalControlContext);
};
