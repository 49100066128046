'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion } from '@edeeone/juan-core/components/accordion/Accordion';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import Link from 'next/link';
import { footerLinksClassNames } from '@edeeone/juan-core/components/footer/footerLinks.styles';
export const FooterLinks = ({ design = 'primary', styles, data, heading, headingUrl, }) => {
    const finalStyles = footerLinksClassNames(styles, design);
    const HeadingElement = headingUrl ? 'a' : 'h2';
    const content = (data.length != 0 && (_jsx("ul", { className: finalStyles.links, children: data.map((item, index) => {
            // const LinkElement = item?.url ? 'Link' : 'span';
            return (_jsxs("li", { className: twMergeJuan(finalStyles.item, item?.url && finalStyles.item__link), children: [item?.url && _jsx(Link, { href: item?.url, children: item?.name }), item?.url === undefined && _jsx("p", { children: item?.name })] }, `FooterLinks_${heading}_${item?.id}_${index}}`));
        }) }))) ||
        null;
    return (_jsx(_Fragment, { children: (data.length != 0 || heading != '') && (_jsx("div", { className: finalStyles.container, children: _jsxs(_Fragment, { children: [design == 'primary' && (_jsxs(_Fragment, { children: [_jsx("div", { className: finalStyles.accordion, children: _jsx(Accordion, { styles: { content__custom: finalStyles.accordion_content }, items: [
                                        {
                                            heading: heading || '',
                                            content,
                                        },
                                    ] }) }), _jsxs("div", { className: finalStyles.list, children: [heading && (_jsx(HeadingElement, { className: twMergeJuan(finalStyles.header, headingUrl && finalStyles.header__link), href: headingUrl, children: heading })), content] })] })), design == 'secondary' && (_jsx("div", { className: finalStyles.list, children: content }))] }) })) }));
};
