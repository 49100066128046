'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { ListingBlog } from './comp/ListingBlog';
import { ListingBrands } from './comp/ListingBrands';
import { ListingCategories } from './comp/ListingCategories';
import { ListingGroups } from './comp/ListingGroups';
import { ListingProducts } from './comp/ListingProducts';
import { ListingRecentlyVisited, RecentlyVisitedAll, } from './comp/ListingRecentlyVisited';
import { ListingSearchHistory } from './comp/ListingSearchHistory';
import { WhispererEmpty } from './WhispererEmpty';
import { whispererResultsClassNames } from './WhispererResults.styles';
import { useWhispererStore } from './WhispererStoreClient';
export const WhispererResults = () => {
    const finalStyles = whispererResultsClassNames();
    const { hasEmptyContent, totalCountWithHidden, hasContent, isRecentlyVisitedVisible, linkAll, hasMinimalCharacters, } = useWhispererStore();
    const { t } = useI18n('Whisperer');
    return (_jsx(_Fragment, { children: _jsx("div", { className: finalStyles.popover, tabIndex: -1, children: _jsxs("div", { className: finalStyles.container, children: [_jsx(WhispererEmpty, { design: "primary" }), (hasEmptyContent || hasContent) && (_jsxs("div", { className: finalStyles.content, children: [_jsx(ListingRecentlyVisited, {}), _jsx(ListingSearchHistory, {}), hasContent && (_jsxs(_Fragment, { children: [_jsx("div", { className: finalStyles.listingCategoriesMobile, children: _jsx(ListingCategories, { showMoreLink: true }) }), _jsxs("div", { className: finalStyles.listingProducts, children: [_jsx(WhispererEmpty, { design: "secondary" }), _jsx(ListingProducts, {})] }), _jsxs("div", { className: finalStyles.listingOther, children: [_jsx(WhispererEmpty, { design: "tertiary" }), _jsx("div", { className: finalStyles.listingCategoriesDesktop, children: _jsx(ListingCategories, {}) }), _jsx(ListingBrands, {}), _jsx(ListingGroups, {}), _jsx(ListingBlog, {})] })] }))] })), _jsx(RecentlyVisitedAll, {}), hasMinimalCharacters &&
                        !isRecentlyVisitedVisible &&
                        totalCountWithHidden > 0 && (_jsx("div", { className: finalStyles.buttonContainer, children: _jsx(Button, { textBadge: `(${totalCountWithHidden})`, href: linkAll, children: t('all') }) }))] }) }) }));
};
