'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownItemProduct } from '@edeeone/juan-core/components/dropdown/DropdownItemProduct';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { formatNumber } from '@edeeone/juan-core/utils/formatNumber';
import { headerCartClassNames } from './HeaderCart.styles';
export const HeaderCartList = ({ styles, data, }) => {
    const finalStyles = headerCartClassNames(styles);
    const { currency, locale } = useLocation();
    return (_jsx("div", { className: finalStyles.itemsContainer, children: _jsx("div", { className: finalStyles.items, children: data?.map((item) => {
                const { product, totalPriceWithoutPolicies, id, quantity } = item ?? {};
                if (!product)
                    return null;
                return (_jsx(DropdownItemProduct, { name: product?.attributes?.name, motive: product?.attributes?.relatedFiles, quantity: `${formatNumber(quantity, {
                        maximumFractionDigits: 1,
                        locales: locale,
                    })} ${product?.attributes?.unit}`, url: product?.attributes?.url, prices: {
                        priceForSale: {
                            priceWithTax: totalPriceWithoutPolicies?.withTax,
                            priceWithoutTex: totalPriceWithoutPolicies?.withoutTax,
                            currency,
                        },
                        allPricesForSale: [],
                    } }, `cart-${id}`));
            }) }) }));
};
