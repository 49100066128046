import { useRegistrationsMeQuery } from '@edeeone/edee-registration/graphql/registration.codegen';
import { useCookie, getCookiesFromDocument } from '@edeeone/juan-core/Cookies';
import { useEffect, useRef } from 'react';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
export const useMe = () => {
    const { data, networkStatus, loading } = useRegistrationsMeQuery({
        notifyOnNetworkStatusChange: true,
        context: {
            tags: ['userProfile'],
        },
    });
    // WORKAROUND: treatment of the condition when user cookies are deleted from the browser. When navigating to another page, the cookies are updated, but the user header still needs to be updated
    const { cookie: userIn } = useCookie('user_in', undefined);
    const isInitialMount = useRef(true);
    const isLogged = !!data?.registrationsMe?.isLogged;
    useEffect(() => {
        // skip initial mount effect
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        // cookies in the context may not be synchronized with cookies in the document
        const userInDocument = getCookiesFromDocument().user_in;
        // if not loading and userIn is different from the data
        if (!loading && isLogged !== !!userInDocument) {
            refreshTags(['userProfile']);
        }
    }, [userIn, loading, isLogged]);
    return data?.registrationsMe;
};
