'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLinkRoute } from '@edeeone/juan-core/routes/useLinkRoute';
import { ShippingDiscount } from '@edeeone/shop-orders/components/shippingDiscount/ShippingDiscount.client';
export const HeaderCartDropdownFooter = () => {
    const { t } = useI18n('HeaderCart');
    const cartPath = useLinkRoute({
        id: '/shop-orders/cart',
    });
    return (_jsxs(_Fragment, { children: [_jsx(ShippingDiscount, {}), _jsx(Button, { href: cartPath?.url, icon: 'shoppingCart', design: "primary", children: t('show') })] }));
};
