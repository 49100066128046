import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const cookiePreferenceSectionDefaultClassNames = /* tw */ {
    container: 'cookie-section flex flex-col gap-4 md:gap-6 pb-8 md:pb-6 border-b border-gray-20',
    headingSection: 'cookie-section__heading-section flex justify-between items-center',
    heading: 'cookie-section__heading text-base leading-10 font-semibold',
    description: 'cookie-section__description text-gray-60 text-xs leading-8 md:mr-56',
    description_a: 'underline transition-common hover:no-underline',
    toggle: 'cookie-section__toggle form-group relative',
    accepted: 'cookies-section__accepted text-success text-xs',
    // section v rámci nastavení v cookie liště
    primary: {},
    //
    // section v rámci nastavení v Mém účtu
    secondary: {
        container: 'sm:pr-10 last-of-type:border-0',
    },
};
export function cookiePreferenceSectionClassNames(...args) {
    return getMemoizedFinalClassnames('cookiePreferenceSectionClassNames', cookiePreferenceSectionDefaultClassNames, ...args);
}
