'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { getHref } from '@edeeone/string/getHref';
import { WhispererResult } from '../WhispererResult';
import { useWhispererStore } from '../WhispererStoreClient';
import { ResultSectionEnum } from '../WhispererTypes';
import { listingBrandsClassNames } from './ListingBrands.styles';
import { WhispererHeading } from './WhispererHeading';
import { WhispererItem } from './WhispererItem';
export const ListingBrands = () => {
    const finalStyles = listingBrandsClassNames();
    const { t } = useI18n('Whisperer');
    const { data, brandsCount, linkAll } = useWhispererStore();
    // TODO limit je potřeba na úrovni dotazu ne až při renderování
    const { brands: limit } = CONFIG.fulltext.whispererConfig.maxAmountShown;
    const isVisible = brandsCount > 0;
    if (!isVisible) {
        return null;
    }
    return (_jsxs(WhispererResult, { design: "secondary", styles: { container__custom: finalStyles.container }, children: [_jsx("div", { className: finalStyles.headingContainer, children: _jsx(WhispererHeading, { href: getHref(linkAll, {
                        section: ResultSectionEnum.brands,
                    }), heading: t('brands'), count: data?.edeeFulltextBrands?.totalRecordCount || 0 }) }), data?.edeeFulltextBrands?.data?.map((single, i) => {
                if (i < limit)
                    return (_jsx("div", { children: _jsx(WhispererItem, { href: single?.facetEntity?.attributes?.url, heading: single?.facetEntity?.attributes?.name }) }, `brand-${single?.facetEntity?.attributes?.url || i}`));
            })] }));
};
