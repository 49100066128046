import { createContext, useContext } from 'react';
export const MegamenuContext = createContext({
    maxDepth: 2,
    open: [],
    close: () => { },
    setOpen: () => { },
    hideMotiveOnTopLevel: false,
});
export const useMegamenuContext = function () {
    return useContext(MegamenuContext);
};
