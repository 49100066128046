import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const headerComparisonDefaultClassNames = /* tw */ {
    container: 'header-comparison max-h-[175px] overflow-y-auto custom-scrollbar px-6 flex',
    items: 'header-comparison__item header-products flex flex-col gap-6 items-center w-full',
    button: 'relative block',
    buttonIcon: 'relative block',
    label: 'label label--header',
    icon: 'ico ico--header',
};
export function headerComparisonClassNames(...args) {
    return getMemoizedFinalClassnames('headerComparisonClassNames', headerComparisonDefaultClassNames, ...args);
}
