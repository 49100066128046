'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { linkStageInModalAction } from '@edeeone/edee-stage/components/LinkStageInModal.action';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
export const LinkStageInModal = function ({ item, children, }) {
    const { triggerModalServerAction } = useServerActionModal();
    const article = linkStageInModalAction.bind(null, item);
    return (_jsx("form", { action: () => {
            return triggerModalServerAction(article);
        }, children: _jsx("button", { type: "submit", children: children }) }));
};
