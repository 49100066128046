import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { Fragment } from 'react';
import { productVariantClassNames } from './productVariant.styles';
export const ProductVariants = ({ id, 
// @ts-ignore
parametersCodes = CONFIG.componentSettings?.Category?.ProductVariants
    ?.parametersCodes || [], items = [], separator = ' | ', design = 'primary', styles, }) => {
    const finalStyles = productVariantClassNames(styles, design);
    const { t } = useI18n('ProductVariants');
    if (design == 'primary') {
        if (parametersCodes.length == 0) {
            if (items.length > 0) {
                return (_jsx("p", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), id: id, children: t('numberOfVariants', {
                        pathPrefix: ['ProductVariants'],
                    }) }));
            }
        }
        else {
            items = items.filter((item) => {
                return parametersCodes.includes(item.referencedEntity.parameter?.referencedEntity?.attributes?.code);
            });
            items = [...new Set(items)];
            return (_jsx("div", { id: id, className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: items.map((item, index) => {
                    return (_jsxs(Fragment, { children: [_jsx("span", { className: finalStyles.item +
                                    ' ' +
                                    item.referencedEntity?.attributes?.code, title: item.referencedEntity?.attributes?.name, children: item.referencedEntity?.attributes?.name }), _jsx("span", { className: finalStyles.separator, children: index < items.length - 1 && separator })] }, index));
                }) }));
        }
    }
    // else if (design == 'secondary') {
    return (_jsx("div", { id: id, className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [...new Set(items)].map((item, index) => {
            if (item.referencedEntity.parameter == null ||
                item.referencedEntity.parameter.referencedEntity == null)
                return null;
            return (_jsxs("div", { className: finalStyles.item, children: [_jsxs("span", { className: finalStyles.parameter, children: [item.referencedEntity.parameter.referencedEntity.attributes.name, ":", ' '] }), _jsx("span", { className: finalStyles.parameterValue, children: item.referencedEntity?.attributes?.name })] }, `ProductVariants_${index}`));
        }) }));
    // }
};
