import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererResultsDefaultClassNames = /* tw */ {
    popover: 'fulltext-autocomplete__popover hidden sm:visible group-[.is-open]:block sm:group-[:not(.is-active)]:hidden sm:group-[.is-active]:visible fixed top-0 left-0 sm:left-[calc((100vw_-_43rem)_/_2)] max-h-screen sm:h-auto md:-top-8 md:absolute z-40 origin-center md:left-1/2 md:-translate-x-1/2',
    container: 'fulltext-autocomplete__content-container bg-white flex flex-wrap gap-4 relative z-30 pb-12 sm:pb-[92px] px-6 sm:px-12 pt-40 sm:pt-44 lg:px-[65px] rounded-none sm:rounded-3xl lg:max-w-[870px] w-screen max-h-[100dvh] md:max-h-screen min-h-[100px] sm:max-w-[43rem] md:max-w-5xl overflow-y-scroll sm:overflow-y-visible group empty:hidden',
    container__open: 'is-open',
    content: 'fulltext-autocomplete__content flex flex-wrap gap-12 sm:overflow-x-hidden sm:overflow-y-auto custom-scrollbar sm:max-h-[calc(100vh_-_350px)] xl:max-h-[calc(100vh_-_260px)] w-full mb-12 sm:mb-0',
    listingProducts: 'fulltext-autocomplete__column-products w-full md:w-[calc(50%_-_1.5rem)] empty:hidden overflow-hidden',
    // zobrazot na mobilu druhé na desktopu první
    listingProducts__recently: 'order-2 sm:order-1',
    listingCategoriesMobile: 'fulltext-autocomplete__column-categories-mobile md:hidden empty:hidden',
    listingCategoriesDesktop: 'fulltext-autocomplete__column-categories-desktop hidden md:block empty:hidden',
    listingOther: 'fulltext-autocomplete__column-other w-full md:w-[calc(50%_-_1.5rem)] flex flex-wrap flex-col gap-12 justify-start empty:hidden',
    inputContainer: 'sm:hidden relative grid-in-input',
    buttonContainer: 'fulltext-autocomplete__button-result-container flex md:block md:text-center w-full md:w-auto grid-in-all justify-center sm:bg-white sm:absolute sm:left-0 sm:right-0 sm:bottom-0 sm:px-20 md:px-12 sm:py-8 sm:rounded-bl-3xl sm:rounded-br-3xl',
};
export function whispererResultsClassNames(...args) {
    return getMemoizedFinalClassnames('whispererResultsClassNamesNew', whispererResultsDefaultClassNames, ...args);
}
