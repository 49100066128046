import { getMemoizedFinalClassnames } from '@edeeone/juan-core/hooks/useFinalClassnames';
export const whispererContainerDefaultClassNames = /* tw */ {
    container: 'fulltext-autocomplete relative sm:absolute w-auto sm:w-full group lg:max-w-[870px] top-0 rounded-full',
    container__active: 'is-active z-50',
    container__open: 'is-open z-50', //PPI mobil má jiné chování než desktop
    overlay: 'fulltext-autocomplete__overlay hidden group-[.is-open]:block sm:group-[:not(.is-active)]:hidden sm:group-[.is-active]:visible overlay-common !pointer-events-auto  z-20',
    overlay__trap: '!pointer-events-none !bg-none',
    inputContainer: 'fulltext-autocomplete__query group-[.is-open]:shadow-whisperer sm:group-[.is-open]:shadow-none group-[.is-open]:flex group-[.is-open]:w-screen group-[.is-open]:z-50 sm:group-[:not(.is-active)]:z-auto sm:group-[.is-active]:z-50 group-[.is-open]:pl-6 group-[.is-open]:pr-32 group-[.is-open]:py-4 group-[.is-open]:top-0 group-[.is-open]:left-0 group-[.is-open]:bg-white sm:group-[.is-open]:bg-transparent  hidden sm:block sm:group-[.is-active]:w-[25rem] sm:group-[.is-active]:top-8 sm:group-[.is-active]:left-[calc(((100vw_-_43rem)_/_2)_+_9.4rem)] md:group-[.is-open]:w-auto',
    inputContainer__active: 'block',
    toggleContainer: 'fulltext-autocomplete__toggle-container sm:hidden sm:absolute right-0 top-0',
    toggle: 'fulltext-autocomplete__toggle w-9 h-9',
    closeBtn: 'fulltext-autocomplete__close hidden sm:block group-[.is-open]:block sm:group-[:not(.is-active)]:hidden sm:group-[.is-active]:visible fixed w-[46px] h-[46px] top-6 right-6 z-50 md:absolute sm:w-6 sm:h-6 sm:z-40 sm:top-14 md:top-7 sm:right-[calc(((100vw_-_43rem)_/_2)_+_3rem)] md:-right-[170px] xl:-right-[55px] 2xl:-right-[28px]',
    closeIcon: 'ico w-8 h-8 sm:w-6 sm:h-6 m-auto fill-gray-60 hover:fill-black transition-common',
};
export function whispererContainerClassNames(...args) {
    return getMemoizedFinalClassnames('whispererContainerClassNamesNew', whispererContainerDefaultClassNames, ...args);
}
