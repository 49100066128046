import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useCartGetShippingDiscountSuspenseQuery } from '../graphql/cart.codegen';
export const useShippingDiscount = () => {
    const { region } = useLocation();
    const { data } = useCartGetShippingDiscountSuspenseQuery({
        variables: {
            args: {
                catalogRegion: region,
            },
        },
        context: {
            tags: ['orders', 'userProfile'],
        },
        // skip: !hasSession,
    });
    return data?.cartGetShippingDiscount;
};
