import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const dropdownItemProductDefaultClassNames = /* tw */ {
    container: 'header-products__item flex justify-between w-full items-center gap-6 group/item',
    content: 'header-products__content flex gap-6 cursor-pointer items-center w-full',
    motiveContainer: 'header-products__motive shrink-0 w-30 h-30 overflow-hidden relative',
    motive: '',
    text: 'header-products__text flex flex-col text-gray-70 text-xs',
    title: 'header-products__title text-sm font-medium text-gray-70 group-hover/item:text-black group-hover/item:underline transition-common line-clamp-2',
    perex: 'header-products__perex text-xs text-gray-60',
    quantity: 'header-products__quantity text-xs text-gray-60',
    quantityPrice: 'header-products__quantity-price flex gap-4 items-center',
};
export function dropdownItemProductClassNames(...args) {
    return getMemoizedFinalClassnames('dropdownItemProductClassNames', dropdownItemProductDefaultClassNames, ...args);
}
