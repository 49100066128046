import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const cookiePreferencesFormDefaultClassNames = /* tw */ {
    container: 'cookie-form flex flex-col gap-6 mt-20 mb-12',
    form: 'cookie-form__form flex flex-col md:block',
    heading: 'cookie-form__heading text-lg leading-normal font-semibold mb-8',
    description: 'cookie-form__description text-gray-70 text-sm leading-10 mb-12',
    description_a: 'underline transition-common hover:no-underline',
    // form v cookie liště
    primary: {
        form: 'mt-8 mb-24 mx-12 sm:mx-[7.5rem]',
    },
    // form v rámci Mého účtu
    secondary: {},
};
export function cookiePreferencesFormClassNames(...args) {
    return getMemoizedFinalClassnames('cookiePreferencesFormClassNames', cookiePreferencesFormDefaultClassNames, ...args);
}
