import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const footerLinksDefaultClassNames = /* tw */ {
    container: 'flex',
    header: '',
    header__link: '',
    links: '',
    item: '',
    accordion: '',
    accordion_content: '',
    list: '',
    item__link: '',
    // výchozí výpis odkazů v zápatí (accordion/ve vyšších rozlišeních strukturovaný výpis)
    primary: {
        container: 'footer-links flex-col ',
        header: 'footer-links__header pt-4 text-xs font-semibold uppercase relative before:content-[""] before:absolute before:block before:top-0 before:left-0 before:bg-green-light before:h-[3px] before:w-14',
        header__link: 'hover:underline transition-common',
        links: 'footer-links__links flex flex-col gap-8 xl:items-start',
        item: 'footer-links__item text-s text-gray-70 font-normal',
        accordion: 'footer-links__accordion block sm:hidden',
        accordion_content: '!pb-8',
        list: 'footer-links__list hidden sm:flex flex-col gap-12',
        item__link: 'hover:underline transition-common',
    },
    // jednoduchý výpis odkazů (odkazy u copyrightu)
    secondary: {
        list: 'flex',
        links: 'footer-copyright-links flex w-full flex-row flex-wrap gap-8 justify-center lg:items-start lg:gap-10 lg:w-auto',
        item: 'footer-copyright-links__item before:content-[""] before:w-[1px] before:h-full before:bg-gray-30 before:block before:absolute before:top-0 before:-left-5 relative first:before:content-none first:w-full lg:first:w-auto first:text-center lg:first:text-left',
        item__link: 'footer-copyright-link__link hover:underline transition-common',
    },
};
export function footerLinksClassNames(...args) {
    return getMemoizedFinalClassnames('footerLinksClassNames', footerLinksDefaultClassNames, ...args);
}
