'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { SvgSpriteIcon } from '../../svgSpriteIcon/SvgSpriteIcon';
import { radioGroupClassNames } from './radioGroup.styles';
export const RadioGroup = ({ id, name, onChange, onBlur, styles, items = [], design = 'primary', initialValue, groupLabel, hideValidationMessage = false, ...props }) => {
    const { register, watch, formState: { errors }, } = useFormContext();
    // NOTE: if the name is undefined, do not register nor control the field in the form
    const currentValue = name ? watch(name) : undefined;
    const registerProps = name
        ? register(name, {
            value: currentValue,
            onChange,
            onBlur,
        })
        : undefined;
    const error = name ? get(errors, name) : undefined;
    const { t } = useI18n('Form');
    const finalStyles = radioGroupClassNames(styles, design);
    return (_jsxs("div", { className: twMergeJuan(finalStyles.group, finalStyles.group__custom, error && finalStyles.group__error), children: [groupLabel && (_jsx("label", { className: twMergeJuan(finalStyles.groupLabel, finalStyles.groupLabel__custom, error && finalStyles.label__error), children: groupLabel })), items.map(({ label, value, itemLabelPrefix, itemLabelSuffix }) => {
                return (_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsxs("div", { className: finalStyles.content, children: [_jsx("input", { checked: initialValue === value || undefined, ...props, type: "radio", value: value, id: `${id}-${value}`, className: twMergeJuan(finalStyles.input, finalStyles.input__custom), ...registerProps }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: finalStyles.icon })] }), _jsxs("label", { className: twMergeJuan(finalStyles.label, value === currentValue && finalStyles.label__checked), htmlFor: `${id}-${value}`, children: [itemLabelPrefix && (_jsx("span", { className: finalStyles.labelPrefix, children: itemLabelPrefix })), _jsx("span", { children: label }), itemLabelSuffix && (_jsx("span", { className: finalStyles.labelSuffix, children: itemLabelSuffix }))] })] }, value));
            }), !hideValidationMessage && error?.message && (_jsx("p", { className: finalStyles.error, children: t(error.message, {
                    field: (_jsx("strong", { className: finalStyles.errorField, children: groupLabel })),
                }) }))] }));
};
